/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import Select from "react-select";
import { customStylesWithFixedHeight } from '../../CADComponents/Utility/CustomStylesForReact';
import NameSearchPage from '../../Components/Pages/SearchAdvancePage/NameSearchPage/NameSearchPage';
import PropertySearchPage from '../../Components/Pages/SearchAdvancePage/PropertySearchPage/PropertySearchPage';
import VehicleSearchPage from '../../Components/Pages/SearchAdvancePage/VehicleSearchPage/VehicleSearchPage';
import EventSearchPage from '../../CADComponents/EventSearchPage';
import ResourceSearchPage from '../../CADComponents/ResourceSearchPage';


function CADQueryIncident() {
    const [selectSearchRecord, setSelectSearchRecord] = useState("")

    const searchRecordData = [
        {
            "id": 4,
            "name": "Event"
        },
        {
            "id": 5,
            "name": "Resource"
        },
        {
            "id": 1,
            "name": "Name"
        },
        {
            "id": 2,
            "name": "Property"
        },
        {
            "id": 3,
            "name": "Vehicle"
        }
    ]


    return (
        <div className="section-body view_page_design">
            <div className="dashboard-main-container">
                <div className="dispatcher-container">
                    <div className="tab-form-row">
                        <div className="col-1 d-flex justify-content-end">
                            <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">Search Record</label>
                        </div>
                        <div className="d-flex col-2">
                            <Select
                                name="TypeOfBolo"
                                styles={customStylesWithFixedHeight}
                                isClearable
                                options={searchRecordData}
                                value={selectSearchRecord ? searchRecordData?.find((i) => i?.id === selectSearchRecord) : ""}
                                getOptionLabel={(v) => v?.name}
                                getOptionValue={(v) => v?.id}
                                onChange={(e) => setSelectSearchRecord(e?.id)}
                                placeholder="Select..."
                                className="w-100"
                            />
                        </div>
                    </div>
                    {selectSearchRecord === 1 && (
                        <NameSearchPage isCAD setSelectSearchRecord={setSelectSearchRecord} />
                    )}
                    {selectSearchRecord === 2 && (
                        <PropertySearchPage isCAD setSelectSearchRecord={setSelectSearchRecord} />
                    )}
                    {selectSearchRecord === 3 && (
                        <VehicleSearchPage isCAD setSelectSearchRecord={setSelectSearchRecord} />
                    )}
                    {selectSearchRecord === 4 && (
                        <EventSearchPage />
                    )}
                    {selectSearchRecord === 5 && (
                        <ResourceSearchPage />
                    )}
                </div>
            </div>
        </div>
    )
}

export default CADQueryIncident