import React, { useContext, useEffect, useRef, useState } from 'react'
import useObjState from '../../CADHook/useObjState';
import Select from "react-select";
import DatePicker from "react-datepicker";
import { colorLessStyle_Select } from '../Utility/CustomStylesForReact';
import { IncidentContext } from '../../CADContext/Incident';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import CallTakerServices from "../../CADServices/APIs/callTaker";
import GeoServices from "../../CADServices/APIs/geo";
import MasterTableListServices from "../../CADServices/APIs/masterTableList";
import { dropDownDataModel } from '../../CADUtils/functions/common';
import Location from '../Common/Location';
import { AgencyContext } from '../../Context/Agency/Index';
import { fetchPostData } from '../../Components/hooks/Api';
import { useNavigate } from 'react-router-dom';
import { toastifyError } from '../../Components/Common/AlertMsg';
import moment from 'moment';

function ResourceSearchPage() {
    const navigate = useNavigate();
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const { setResourceData, } = useContext(AgencyContext);
    const { resourceData } = useContext(IncidentContext);
    const [loginAgencyID, setLoginAgencyID] = useState("");
    const [loginPinID, setLoginPinID] = useState(1);
    const [receiveSourceDropDown, setReceiveSourceDropDown] = useState([]);
    const [shiftDropDown, setShiftDropDown] = useState([])
    const [cfsDropDown, setCFSDropDown] = useState([]);
    const [operatorDropDown, setOperatorDropDown] = useState([]);
    const [flagData, setFlagData] = useState([]);
    const [zoneDropDown, setZoneDropDown] = useState([])
    const [locationStatus, setLocationStatus] = useState(false);
    const [locationData, setLocationData] = useState();
    const [isSelectLocation, setIsSelectLocation] = useState(false);
    const [IsChangeData, setIsChangeData] = useState(false);
    const [
        resourceState,
        setResourceState,
        handleResourceState,
        clearResourceState,
    ] = useObjState({
        agencyCode: "",
        dateFilter: "none",
        reportedFromDate: "",
        reportedToDate: "",
        reportedFromTime: "",
        reportedToTime: "",
        receiveSource: "",
        CADEventFrom: "",
        CADEventTo: "",
        OperatorID: "",
        RMSIncidentTo: "",
        RMSIncidentFrom: "",
        reportedCSF: "",
        resourceType: "",
        resource: "",
        primaryOfficer: [],
        primaryResource: "",
        shift: "",
        zone: "",
        location: "",
        apt: "",
        intersectionSt: "",
        intersectionSt1: "",
        city: "",
        commonPlaceName: "",
        zip: ""
    });

    const receiveSourceKey = `/CAD/CallTakerReceiveSource/GetData_ReceiveSource`;
    const { data: receiveSourceData, isSuccess: isFetchReceiveSourceData } =
        useQuery(
            [receiveSourceKey, { Action: "GetData_ReceiveSource" }],
            CallTakerServices.getReceiveSource,
            {
                refetchOnWindowFocus: false,
                enabled: !!loginAgencyID,
            }
        );

    const CFSCodeKey = `/CAD/MasterCallforServiceCode/InsertCallforServiceCode`;
    const { data: cfsData, isSuccess: isFetchCFSData } = useQuery(
        [
            CFSCodeKey,
            {
                Action: "GET",
                IsActive: 1
            }
        ],
        MasterTableListServices.getCFS,
        {
            refetchOnWindowFocus: false,
        }
    );

    const GetResourceTypeKey = `/CAD/MasterResourceType/GetResourceType`;
    const { data: resourceTypeData } = useQuery(
        [GetResourceTypeKey, { Action: "GET", IsActive: 1 },],
        MasterTableListServices.getResourceType,
        {
            refetchOnWindowFocus: false,
        }
    );

    const shiftDataKey = `/CAD/MasterResourceShift/GetData_Shift`;
    const { data: shiftData, isSuccess: isFetchShiftData } = useQuery(
        [shiftDataKey, { AgencyID: loginAgencyID },],
        MasterTableListServices.getShift,
        {
            refetchOnWindowFocus: false,
            enabled: !!loginAgencyID
        }
    );

    const getTypeOfFlagKey = `/CAD/MasterTypeOfFlag/TypeOfFlag`;
    const { data: getTypeOfFlagData, isSuccess: isFetchGetTypeOfFlag } = useQuery(
        [getTypeOfFlagKey, {
            "Action": "GET",
            "IsActive": 1,
            "AgencyID": loginAgencyID,
        },],
        MasterTableListServices.getTypeOfFlag,
        {
            refetchOnWindowFocus: false,
            retry: 0,
            enabled: !!loginAgencyID
        }
    );
    const getOperatorKey = `/CAD/Operator_search/Operator`;
    const { data: getOperatorData, isSuccess: isFetchGetOperator } = useQuery(
        [getOperatorKey, {
            "AgencyID": loginAgencyID,
        },],
        MasterTableListServices.getOperator,
        {
            refetchOnWindowFocus: false,
            retry: 0,
            enabled: !!loginAgencyID
        }
    );

    const getZoneKey = `/CAD/GeoPetrolZone/GetData_Zone`;
    const { data: getZoneData, isSuccess: isFetchGeoZoneData } = useQuery(
        [getZoneKey, { IsActive: 1 },],
        MasterTableListServices.getDataZone,
        {
            refetchOnWindowFocus: false,
        }
    );

    useEffect(() => {
        if (isFetchGeoZoneData && getZoneData) {
            const data = JSON.parse(getZoneData?.data?.data)?.Table || [];
            setZoneDropDown(dropDownDataModel(data, "ZoneID", "ZoneDescription"));
        }
    }, [isFetchGeoZoneData, getZoneData]);

    useEffect(() => {
        if (!resourceState?.location) {
            setResourceState((prevState) => ({
                ...prevState,
                Id: "",
            }));
        }

        const fetchLocationData = async () => {
            try {
                const response = await GeoServices.getLocationData({
                    Location: resourceState?.location,
                });
                const data = JSON.parse(response?.data?.data)?.Table || [];
                setLocationData(data);

            } catch (error) {
                console.error("Error fetching location data:", error);
                setLocationData([]);
            }
        };

        if (resourceState?.location) {
            fetchLocationData();
        }
    }, [resourceState?.location, isSelectLocation]);


    useEffect(() => {
        if (getOperatorData && isFetchGetOperator) {
            const data = JSON.parse(getOperatorData?.data?.data);
            setOperatorDropDown(data?.Table)
        } else {
            setOperatorDropDown([])
        }
    }, [getOperatorData, isFetchGetOperator])

    useEffect(() => {
        if (getTypeOfFlagData && isFetchGetTypeOfFlag) {
            const data = JSON.parse(getTypeOfFlagData?.data?.data);
            setFlagData(data?.Table)
        } else {
            setFlagData([])
        }
    }, [getTypeOfFlagData, isFetchGetTypeOfFlag])

    useEffect(() => {
        if (shiftData && isFetchShiftData) {
            const data = JSON.parse(shiftData?.data?.data);
            setShiftDropDown(data?.Table || [])
        }
    }, [shiftData, isFetchShiftData])

    useEffect(() => {
        if (localStoreData) {
            setLoginPinID(localStoreData?.PINID);
            setLoginAgencyID(localStoreData?.AgencyID);
        }
    }, [localStoreData]);

    useEffect(() => {
        if (isFetchCFSData && cfsData) {
            const data = JSON.parse(cfsData?.data?.data)?.Table;
            setCFSDropDown(data);
        }
    }, [isFetchCFSData, cfsData]);

    useEffect(() => {
        if (isFetchReceiveSourceData && receiveSourceData) {
            const data = JSON.parse(receiveSourceData?.data?.data);
            setReceiveSourceDropDown(data?.Table || []);
        }
    }, [isFetchReceiveSourceData, receiveSourceData]);


    const startRef = useRef();
    const onKeyDown = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            startRef.current.setOpen(false);
        }
    };
    const getEventDateRange = (dateFilter) => {
        const currentDate = new Date();
        let startDate = null;

        switch (dateFilter) {
            case "24hr":
                startDate = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000);
                break;
            case "lastWeek":
                startDate = new Date();
                startDate.setDate(currentDate.getDate() - 7);
                startDate.setHours(0, 0, 0, 0);
                break;
            case "lastMonth":
                startDate = new Date();
                startDate.setMonth(currentDate.getMonth() - 1);
                startDate.setHours(0, 0, 0, 0);
                break;
            case "lastYear":
                startDate = new Date();
                startDate.setFullYear(currentDate.getFullYear() - 1);
                startDate.setHours(0, 0, 0, 0);
                break;
            default:
                return "";
        }

        return startDate.toISOString().split("T")[0];
    };
    async function handleSearch() {
        const payload = {
            AgencyID: loginAgencyID,
            IncidentNumber: resourceState?.RMSIncidentFrom,
            IncidentNumberTo: resourceState?.RMSIncidentTo,
            ReportedDateTO: resourceState?.reportedToDate,
            ReportedDate: resourceState?.reportedFromDate,
            ReceiveSourceID: resourceState?.receiveSource?.ReceiveSourceID,
            CADCFSCodeID: "",
            CADIncidentNumberFrom: resourceState?.CADEventFrom,
            CADIncidentNumberTo: resourceState?.CADEventTo,
            CrimeLocation: resourceState?.location,
            ReportedCFSCodeID: resourceState?.reportedCSF?.CallforServiceID,
            FoundCFSCodeID: resourceState?.foundCSF?.CallforServiceID,
            EventDate: getEventDateRange(resourceState?.dateFilter),
            ResourceNumber: resourceState?.resource?.length > 0 ? resourceState?.resource?.map(r => r?.ResourceID).join(",") : "",
            ResourceType: resourceState?.resourceType?.ResourceTypeID,
            Shift: resourceState?.shift?.ShiftId,
            City: resourceState?.city,
            InterDirectionPrefix: resourceState?.intersectionSt,
            InterDirectionSufix: resourceState?.intersectionSt1,
            CommonPlace: resourceState?.commonPlaceName,
            ZipCode: resourceState?.zip,
            ApartmentNo: resourceState?.apt,
            OperatorID: resourceState?.OperatorID?.length > 0 ? resourceState?.OperatorID?.map(o => o?.PINID).join(",") : "",
            PrimeryOfficer: resourceState?.primaryOfficer?.length > 0 ? resourceState?.primaryOfficer?.map(o => o?.PINID).join(",") : "",
            PrimeryUnit: resourceState?.primaryResource?.length > 0 ? resourceState?.primaryResource?.map(pu => pu?.ResourceID).join(",") : "",
            ZoneCode: resourceState?.zone?.value,
            ZoneID: resourceState?.zone?.value,
            ReportedFromTime: resourceState?.reportedFromTime ? moment(resourceState?.reportedFromTime).format('HH:mm') : "",
            ReportedToTime: resourceState?.reportedToTime ? moment(resourceState?.reportedToTime).format('HH:mm') : "",
        };

        if (loginAgencyID) {
            fetchPostData("/CAD/ResourceEvent/ResourceEvent_Search", payload).then((res) => {
                if (res.length > 0) {
                    setResourceData(res);
                    navigate('/cad/resourceSearch');
                    clearResourceState();
                } else {
                    setResourceData([]);
                    toastifyError("Data Not Available");
                }
            });
        } else {
            toastifyError("Please Enter Details");
        }
    }

    return (
        <div className="section-body pt-1 p-1 bt" >
            <div className="div">
                <div className="dark-row" >
                    <div className="col-12 col-sm-12">
                        <div className="card Agency ">
                            <div className="card-body" >
                                <div className="row " style={{ marginTop: '-10px' }}>
                                    <div className="col-12 row my-2">
                                        <div className="d-flex align-self-center justify-content-start" >
                                            <div className='d-flex align-items-center justify-content-start' style={{ gap: '5px' }}>
                                                <label for="closedEvents" className='tab-form-label text-nowrap'>Select Agency Type</label>
                                                <Select
                                                    styles={colorLessStyle_Select}
                                                    placeholder="Select"
                                                    options={[]}
                                                    getOptionLabel={(v) => v?.ShortName}
                                                    getOptionValue={(v) => v?.AgencyID}
                                                    isClearable
                                                    value={resourceState?.agencyCode}
                                                    onChange={(e) => { handleResourceState("agencyCode", e) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <fieldset>
                                            <legend>Resource Search</legend>
                                            <div className="row align-self-center justify-content-center px-1 mt-4 mb-2">
                                                <div className="d-flex align-self-center justify-content-center" style={{ width: '120px' }}>
                                                    <div className='d-flex align-self-center justify-content-start' style={{ gap: '5px' }}>
                                                        <input type="radio" id="none" value="none" checked={resourceState?.dateFilter === 'none'} onChange={(e) => { handleResourceState("dateFilter", e.target.value); }} />
                                                        <label for="none" className='tab-form-label' style={{ margin: '0', }}>None</label>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-self-center justify-content-start" style={{ width: '90px' }}>
                                                    <div className='d-flex align-self-center justify-content-start' style={{ gap: '5px' }}>
                                                        <input type="radio" id="24hr" value="24hr" checked={resourceState?.dateFilter === '24hr'} onChange={(e) => {
                                                            handleResourceState("dateFilter", e.target.value)
                                                        }} />
                                                        <label for="24hr" className='tab-form-label' style={{ margin: '0', }}>24Hr</label>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-self-center justify-content-start" style={{ width: '120px' }}>
                                                    <div className='d-flex align-self-center justify-content-start' style={{ gap: '5px' }}>
                                                        <input type="radio" id="lastWeek" value="lastWeek" checked={resourceState?.dateFilter === 'lastWeek'} onChange={(e) => {
                                                            handleResourceState("dateFilter", e.target.value)
                                                        }} />
                                                        <label for="lastWeek" className='tab-form-label' style={{ margin: '0', }}>Last Week</label>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-self-center justify-content-start" style={{ width: '120px' }}>
                                                    <div className='d-flex align-self-center justify-content-start' style={{ gap: '5px' }}>
                                                        <input type="radio" id="lastMonth" value="lastMonth" checked={resourceState?.dateFilter === 'lastMonth'} onChange={(e) => {
                                                            handleResourceState("dateFilter", e.target.value)
                                                        }} />
                                                        <label for="lastMonth" className='tab-form-label' style={{ margin: '0', }}>Last Month</label>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-self-center justify-content-start" style={{ width: '120px' }}>
                                                    <div className='d-flex align-self-center justify-content-start' style={{ gap: '5px' }}>
                                                        <input type="radio" id="lastYear" value="lastYear" checked={resourceState?.dateFilter === 'lastYear'} onChange={(e) => {
                                                            handleResourceState("dateFilter", e.target.value)
                                                        }} />
                                                        <label for="lastYear" className='tab-form-label' style={{ margin: '0', }}>Last Year</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                        <fieldset>
                                            <legend>Resource Information</legend>
                                            <div className='row mb-1'>
                                                <div className="col-2 d-flex align-self-center justify-content-end">
                                                    <label for="" className="tab-form-label">
                                                        Reported From Date
                                                    </label>
                                                </div>
                                                <div className="col-2 d-flex align-self-center justify-content-end">
                                                    <DatePicker
                                                        name='startDate'
                                                        id='startDate'
                                                        onChange={(v) => handleResourceState("reportedFromDate", v)}
                                                        selected={resourceState?.reportedFromDate || ""}
                                                        dateFormat="MM/dd/yyyy"
                                                        isClearable={!!resourceState?.reportedFromDate}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        autoComplete="off"
                                                        placeholderText="Select From Date..."
                                                        maxDate={resourceState?.reportedToDate || null}
                                                    />
                                                </div>
                                                <div className="col-2 d-flex align-self-center justify-content-end">
                                                    <label for="" className="tab-form-label">
                                                        Reported To Date
                                                    </label>
                                                </div>
                                                <div className="col-2 d-flex align-self-center justify-content-end">
                                                    <DatePicker
                                                        name='reportedToDate'
                                                        id='reportedToDate'
                                                        onChange={(v) => handleResourceState("reportedToDate", v)}
                                                        selected={resourceState?.reportedToDate || ""}
                                                        dateFormat="MM/dd/yyyy"
                                                        isClearable={!!resourceState?.reportedToDate}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        autoComplete="off"
                                                        placeholderText="Select To Date..."
                                                        minDate={resourceState?.reportedFromDate}
                                                        maxDate={new Date()}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-12 mb-1'>
                                                <div className='row'>
                                                    <div className="col-2 d-flex align-self-center justify-content-end">
                                                        <label for="" className="tab-form-label">
                                                            Reported From Time
                                                        </label>
                                                    </div>
                                                    <div className="col-2 d-flex align-self-center justify-content-end">
                                                        <DatePicker
                                                            selected={resourceState?.reportedFromTime}
                                                            onChange={(e) => handleResourceState("reportedFromTime", e)}
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            timeIntervals={1}
                                                            timeCaption="Time"
                                                            dateFormat="h:mm aa"
                                                            isClearable={!!resourceState?.reportedFromTime}
                                                            placeholderText="Select Time"
                                                        />
                                                    </div>
                                                    <div className="col-2 d-flex align-self-center justify-content-end">
                                                        <label for="" className="tab-form-label">
                                                            Reported To Time
                                                        </label>
                                                    </div>
                                                    <div className="col-2 d-flex align-self-center justify-content-end">
                                                        <DatePicker
                                                            selected={resourceState?.reportedToTime}
                                                            onChange={(e) => handleResourceState("reportedToTime", e)}
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            timeIntervals={1}
                                                            timeCaption="Time"
                                                            dateFormat="h:mm aa"
                                                            isClearable={!!resourceState?.reportedToTime}
                                                            placeholderText="Select Time"
                                                        />
                                                    </div>
                                                    <div className="col-2 d-flex align-self-center justify-content-end">
                                                        <label for="" className="tab-form-label">
                                                            Receive Source
                                                        </label>
                                                    </div>
                                                    <div className="col-2 d-flex align-self-center justify-content-end">
                                                        <Select
                                                            styles={colorLessStyle_Select}
                                                            placeholder="Select"
                                                            options={receiveSourceDropDown}
                                                            getOptionLabel={(v) => v?.ReceiveSourceCode}
                                                            getOptionValue={(v) => v?.ReceiveSourceID}
                                                            isClearable
                                                            onInputChange={(inputValue, actionMeta) => {
                                                                if (inputValue.length > 12) {
                                                                    return inputValue.slice(0, 12);
                                                                }
                                                                return inputValue;
                                                            }}
                                                            value={resourceState?.receiveSource}
                                                            onChange={(e) => { handleResourceState("receiveSource", e) }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-12 mb-1'>
                                                <div className='row'>
                                                    <div className="col-2 d-flex align-self-center justify-content-end">
                                                        <label for="" className="tab-form-label">
                                                            CAD Incident # From
                                                        </label>
                                                    </div>
                                                    <div className="col-2 d-flex align-self-center justify-content-end">
                                                        <input
                                                            className="form-control py-1 new-input"
                                                            name="CADEventFrom"
                                                            placeholder="CAD Incident # From"
                                                            value={resourceState.RMSIncidentFrom}
                                                            onChange={(e) => handleResourceState("CADEventFrom", e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="col-2 d-flex align-self-center justify-content-end">
                                                        <label for="" className="tab-form-label">
                                                            CAD Incident # To
                                                        </label>
                                                    </div>
                                                    <div className="col-2 d-flex align-self-center justify-content-end">
                                                        <input
                                                            className="form-control py-1 new-input"
                                                            name="CADEventTo"
                                                            placeholder="CAD Incident # To"
                                                            value={resourceState.CADEventTo}
                                                            onChange={(e) => handleResourceState("CADEventTo", e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="col-2 d-flex align-self-center justify-content-end">
                                                        <label for="" className="tab-form-label">
                                                            Operator ID
                                                        </label>
                                                    </div>
                                                    <div className="col-2 d-flex align-self-center justify-content-end">
                                                        <Select
                                                            styles={colorLessStyle_Select}
                                                            placeholder="Select"
                                                            options={operatorDropDown}
                                                            getOptionLabel={(v) => v?.FirstName + " " + v?.LastName}
                                                            getOptionValue={(v) => v?.PIN}
                                                            isClearable
                                                            onInputChange={(inputValue, actionMeta) => {
                                                                if (inputValue.length > 12) {
                                                                    return inputValue.slice(0, 12);
                                                                }
                                                                return inputValue;
                                                            }}
                                                            isMulti
                                                            value={resourceState?.OperatorID}
                                                            onChange={(e) => { handleResourceState("OperatorID", e) }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-12 mb-1'>
                                                <div className='row'>
                                                    <div className="col-2 d-flex align-self-center justify-content-end">
                                                        <label for="" className="tab-form-label">
                                                            RMS Incident # From
                                                        </label>
                                                    </div>
                                                    <div className="col-2 d-flex align-self-center justify-content-end">
                                                        <input
                                                            type='number'
                                                            className="form-control py-1 new-input"
                                                            name="RMSIncidentFrom"
                                                            placeholder="RMS Incident # From"
                                                            value={resourceState.RMSIncidentFrom}
                                                            onChange={(e) => handleResourceState("RMSIncidentFrom", e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="col-2 d-flex align-self-center justify-content-end">
                                                        <label for="" className="tab-form-label">
                                                            RMS Incident # To
                                                        </label>
                                                    </div>
                                                    <div className="col-2 d-flex align-self-center justify-content-end">
                                                        <input
                                                            type='number'
                                                            className="form-control py-1 new-input"
                                                            name="RMSIncidentTo"
                                                            placeholder="RMS Incident # To"
                                                            value={resourceState.RMSIncidentTo}
                                                            onChange={(e) => handleResourceState("RMSIncidentTo", e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-12 mb-1'>
                                                <div className='row'>
                                                    <div className="col-2 d-flex align-self-center justify-content-end">
                                                        <label for="" className="tab-form-label">
                                                            Reported CSF
                                                        </label>
                                                    </div>
                                                    <div className="col-5 d-flex align-self-center justify-content-end">
                                                        <Select
                                                            styles={colorLessStyle_Select}
                                                            placeholder="Select"
                                                            options={cfsDropDown}
                                                            getOptionLabel={(v) => v?.CFSCODE + "|" + v?.CFSCodeDescription}
                                                            getOptionValue={(v) => v?.CallforServiceID}
                                                            isClearable
                                                            formatOptionLabel={(option) => {
                                                                return option.CFSCODE + " | " + option.CFSCodeDescription
                                                            }}
                                                            value={resourceState?.reportedCSF}
                                                            onChange={(e) => { handleResourceState("reportedCSF", e) }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-12 mb-1'>
                                                <div className='row'>
                                                    <div className="col-2 d-flex align-self-center justify-content-end">
                                                        <label for="" className="tab-form-label">
                                                            Resource Type
                                                        </label>
                                                    </div>
                                                    <div className="col-2 d-flex align-self-center justify-content-end">
                                                        <Select
                                                            styles={colorLessStyle_Select}
                                                            placeholder="Select"
                                                            options={resourceTypeData?.data?.data?.Table}
                                                            getOptionLabel={(v) => v?.ResourceTypeCode + " | " + v?.description}
                                                            getOptionValue={(v) => v?.ResourceTypeID}
                                                            formatOptionLabel={(option, { context }) => {
                                                                return context === 'menu'
                                                                    ? `${option?.ResourceTypeCode} | ${option?.description}`
                                                                    : option?.ResourceTypeCode;
                                                            }}
                                                            onInputChange={(inputValue, actionMeta) => {
                                                                if (inputValue.length > 12) {
                                                                    return inputValue.slice(0, 12);
                                                                }
                                                                return inputValue;
                                                            }}
                                                            isClearable
                                                            value={resourceState?.resourceType}
                                                            onChange={(e) => { handleResourceState("resourceType", e) }}
                                                        />
                                                    </div>
                                                    <div className="col-2 d-flex align-self-center justify-content-end">
                                                        <label for="" className="tab-form-label">
                                                            Resource#
                                                        </label>
                                                    </div>
                                                    <div className="col-2 d-flex align-self-center justify-content-end">
                                                        <Select
                                                            styles={colorLessStyle_Select}
                                                            placeholder="Select"
                                                            options={resourceData}
                                                            getOptionLabel={(v) => v?.ResourceNumber}
                                                            getOptionValue={(v) => v?.ResourceID}
                                                            isClearable
                                                            isMulti
                                                            onInputChange={(inputValue, actionMeta) => {
                                                                if (inputValue.length > 12) {
                                                                    return inputValue.slice(0, 12);
                                                                }
                                                                return inputValue;
                                                            }}
                                                            value={resourceState?.resource}
                                                            onChange={(e) => { handleResourceState("resource", e) }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-12 mb-1'>
                                                <div className='row'>
                                                    <div className="col-2 d-flex align-self-center justify-content-end">
                                                        <label for="" className="tab-form-label">
                                                            Primary Officer
                                                        </label>
                                                    </div>
                                                    <div className="col-2 d-flex align-self-center justify-content-end">
                                                        <Select
                                                            styles={colorLessStyle_Select}
                                                            placeholder="Select"
                                                            options={operatorDropDown}
                                                            getOptionLabel={(v) => v?.FirstName + " " + v?.LastName}
                                                            getOptionValue={(v) => v?.PIN}
                                                            onInputChange={(inputValue, actionMeta) => {
                                                                if (inputValue.length > 12) {
                                                                    return inputValue.slice(0, 12);
                                                                }
                                                                return inputValue;
                                                            }}
                                                            isMulti
                                                            isClearable
                                                            value={resourceState?.primaryOfficer}
                                                            onChange={(e) => { handleResourceState("primaryOfficer", e) }}
                                                        />
                                                    </div>
                                                    <div className="col-2 d-flex align-self-center justify-content-end">
                                                        <label for="" className="tab-form-label">
                                                            Primary Resource
                                                        </label>
                                                    </div>
                                                    <div className="col-2 d-flex align-self-center justify-content-end">
                                                        <Select
                                                            styles={colorLessStyle_Select}
                                                            placeholder="Select"
                                                            options={resourceData}
                                                            getOptionLabel={(v) => v?.ResourceNumber}
                                                            getOptionValue={(v) => v?.ResourceID}
                                                            isClearable
                                                            isMulti
                                                            onInputChange={(inputValue, actionMeta) => {
                                                                if (inputValue.length > 12) {
                                                                    return inputValue.slice(0, 12);
                                                                }
                                                                return inputValue;
                                                            }}
                                                            value={resourceState?.primaryResource}
                                                            onChange={(e) => { handleResourceState("primaryResource", e) }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-12 mb-1'>
                                                <div className='row'>
                                                    <div className="col-2 d-flex align-self-center justify-content-end">
                                                        <label for="" className="tab-form-label">
                                                            Shift
                                                        </label>
                                                    </div>
                                                    <div className="col-2 d-flex align-self-center justify-content-end">
                                                        <Select
                                                            styles={colorLessStyle_Select}
                                                            placeholder="Select"
                                                            options={shiftDropDown}
                                                            getOptionLabel={(v) => v?.ShiftCode + " | " + v?.ShiftDescription}
                                                            getOptionValue={(v) => v?.ShiftId}
                                                            formatOptionLabel={(option, { context }) => {
                                                                return context === 'menu'
                                                                    ? `${option?.ShiftCode} | ${option?.ShiftDescription}`
                                                                    : option?.ShiftCode;
                                                            }}
                                                            isClearable
                                                            value={resourceState?.shift}
                                                            onChange={(e) => { handleResourceState("shift", e) }}
                                                        />
                                                    </div>
                                                    <div className="col-2 d-flex align-self-center justify-content-end">
                                                        <label for="" className="tab-form-label">
                                                            Zone
                                                        </label>
                                                    </div>
                                                    <div className="col-2 d-flex align-self-center justify-content-end">
                                                        <Select
                                                            styles={colorLessStyle_Select}
                                                            placeholder="Select"
                                                            options={zoneDropDown}
                                                            isClearable
                                                            onInputChange={(inputValue, actionMeta) => {
                                                                if (inputValue.length > 12) {
                                                                    return inputValue.slice(0, 12);
                                                                }
                                                                return inputValue;
                                                            }}
                                                            value={resourceState?.zone}
                                                            onChange={(e) => { handleResourceState("zone", e) }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                        <fieldset>
                                            <legend>Resource Location Information</legend>
                                            <div className='col-12 mb-1 mt-1'>
                                                <div className='row'>
                                                    <div className="col-2 d-flex align-self-center justify-content-end">
                                                        <label for="" className="tab-form-label">
                                                            Location
                                                        </label>
                                                    </div>
                                                    <div className="col-5 w-100 inner-input-fullw pr-2">
                                                        <Location
                                                            {...{
                                                                value: resourceState,
                                                                setValue: setResourceState,
                                                                locationStatus,
                                                                setLocationStatus,
                                                                setIsChangeData,
                                                                setIsSelectLocation,
                                                                locationData,
                                                            }}
                                                            col="location"
                                                            locationID="NameLocationID"
                                                            check={false}
                                                            verify={true}
                                                            page="Name"
                                                            isGEO
                                                        />
                                                    </div>
                                                    <div className="col-2 d-flex align-self-center justify-content-end">
                                                        <label for="" className="tab-form-label">
                                                            Apt#/suit
                                                        </label>
                                                    </div>
                                                    <div className="col-2 d-flex align-self-center justify-content-end">
                                                        <input
                                                            placeholder='APT#/suit'
                                                            type="number"
                                                            className="form-control ml-1 py-1 new-input mr-2"
                                                            maxLength={4}
                                                            value={resourceState?.apt}
                                                            onChange={(e) => { handleResourceState("apt", e.target.value) }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-12 mb-1'>
                                                <div className='row'>
                                                    <div className="col-2 d-flex align-self-center justify-content-end">
                                                        <label for="" className="tab-form-label">
                                                            Intersection St/ST
                                                        </label>
                                                    </div>
                                                    <div className="col-5 d-flex align-items-center justify-content-end">
                                                        <input
                                                            type="text"
                                                            className="form-control py-1 new-input"
                                                            name="intersection1"
                                                            value={resourceState.intersectionSt}
                                                            onChange={(e) => { handleResourceState("intersectionSt", e.target.value) }}
                                                        />
                                                        {"/"}
                                                        <input
                                                            type="text"
                                                            className="form-control ml-1 py-1 new-input mr-2"
                                                            name="intersection2"
                                                            value={resourceState.intersectionSt1}
                                                            onChange={(e) => { handleResourceState("intersectionSt1", e.target.value) }}
                                                        />
                                                    </div>
                                                    <div className="col-2 d-flex align-self-center justify-content-end">
                                                        <label for="" className="tab-form-label">
                                                            City
                                                        </label>
                                                    </div>
                                                    <div className="col-2 d-flex align-self-center justify-content-end">
                                                        <input
                                                            type="text"
                                                            className="form-control ml-1 py-1 new-input mr-2"
                                                            name="intersection2"
                                                            value={resourceState.city}
                                                            onChange={(e) => { handleResourceState("city", e.target.value) }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-12 mb-1'>
                                                <div className='row'>
                                                    <div className="col-2 d-flex align-self-center justify-content-end">
                                                        <label for="" className="tab-form-label">
                                                            Common Name Place
                                                        </label>
                                                    </div>
                                                    <div className="col-5 d-flex align-self-center justify-content-end">
                                                        <input
                                                            type="text"
                                                            className="form-control ml-1 py-1 new-input mr-2"
                                                            name="commonPlaceName"
                                                            value={resourceState.commonPlaceName}
                                                            onChange={(e) => { handleResourceState("commonPlaceName", e.target.value) }}
                                                        />
                                                    </div>
                                                    <div className="col-2 d-flex align-self-center justify-content-end">
                                                        <label for="" className="tab-form-label">
                                                            Zip
                                                        </label>
                                                    </div>
                                                    <div className="col-2 d-flex align-self-center justify-content-end">
                                                        <input
                                                            type="text"
                                                            className="form-control ml-1 py-1 new-input mr-2"
                                                            name="zip"
                                                            value={resourceState.zip}
                                                            onChange={(e) => { handleResourceState("zip", e.target.value) }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                            <div className="btn-box  text-right  mr-1 mb-1" >
                                <button type="button" className="btn btn-sm btn-success mr-1" onClick={() => handleSearch()}>Search</button>
                                <button type="button" data-dismiss="modal" className="btn btn-sm btn-success mr-1 ">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResourceSearchPage