/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useRef, useState } from 'react'
import TitleCmp from '../../CADComponents/Common/TitleCmp';
import { Nav } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import IncidentTabSection from '../../CADComponents/MonitorScreen/TabSections/IncidentTabSection';
import ResourceHistoryTabSection from '../../CADComponents/MonitorScreen/TabSections/ResourceHistoryTabSection';
import ResourceStatusTabSection from '../../CADComponents/MonitorScreen/TabSections/ResourceStatusTabSection';
import CommentsTabSection from "../../CADComponents/MonitorScreen/TabSections/CommentsTabSection";
import NameTabSectionModal from '../../CADComponents/MonitorScreen/TabSections/NameTabSectionModal';
import PropertyTabSectionModal from '../../CADComponents/MonitorScreen/TabSections/PropertyTabSectionModal';
import VehicleTabSectionModal from '../../CADComponents/MonitorScreen/TabSections/VehicleTabSectionModal';
import { AgencyContext } from '../../Context/Agency/Index';
import { base64ToString } from '../../Components/Common/Utility';


function CADDispatcher() {
    const { get_Incident_Count, incidentCount } = useContext(AgencyContext);

    const tabConfig = [
        { key: "incident", label: "Event Info", component: IncidentTabSection, isPopup: false, class: "black" },
        { key: "name", label: `Name ${incidentCount[0]?.NameCount > 0 ? '(' + incidentCount[0]?.NameCount + ')' : ''}`, class: `${incidentCount[0]?.NameCount > 0 ? "blue" : "black"}`, component: NameTabSectionModal, isPopup: false },
        { key: "property", label: `Property ${incidentCount[0]?.PropertyCount > 0 ? '(' + incidentCount[0]?.PropertyCount + ')' : ''}`, class: `${incidentCount[0]?.PropertyCount > 0 ? "blue" : "black"}`, component: PropertyTabSectionModal, isPopup: false },
        { key: "vehicle", label: `Vehicle ${incidentCount[0]?.VehicleCount > 0 ? '(' + incidentCount[0]?.VehicleCount + ')' : ''}`, class: `${incidentCount[0]?.VehicleCount > 0 ? "blue" : "black"}`, component: VehicleTabSectionModal, isPopup: false },
        { key: "resourceStatus", label: "Resource Status", component: ResourceStatusTabSection, isPopup: false, class: "black" },
        { key: "comments", label: "Comments", component: CommentsTabSection, isPopup: false, class: "black" },
        { key: "resourceHistory", label: "Call History", component: ResourceHistoryTabSection, isPopup: false, class: "black" },
        // { key: "changeLog", label: "Change Log", component: "", isPopup: false, class: "black" },
        // { key: "changeLog", label: "Change Log", component: ChangeLogSection, isPopup: false },
    ];

    const useRouteQuery = () => {
        const params = new URLSearchParams(useLocation().search);
        return {
            get: (param) => params.get(param)
        };
    };
    const query = useRouteQuery();
    let IncID = query?.get("IncId");
    if (!IncID) IncID = 0;
    else IncID = parseInt(base64ToString(IncID));

    useEffect(() => {
        if (IncID) {
            get_Incident_Count(IncID)
        }
    }, [IncID])

    const [activeTab, setActiveTab] = useState("incident");
    const ActiveTabComponent = tabConfig.find(tab => tab.key === activeTab)?.component;
    const ActiveTabComponentRef = useRef(null);


    return (
        <div className="section-body view_page_design">
            <div className="dashboard-main-container">
                <div className="dispatcher-container">
                    <TitleCmp title={"CAD Event Details"} />
                    <div className="tab-controller-container">
                        <Nav variant="tabs" className="nav-tabs px-2" style={{ width: "90%" }}>
                            {tabConfig.map((tab) => (
                                tab.isPopup ? (
                                    <Nav.Item key={tab.key}>
                                        <button
                                            type="button"
                                            className={`btn ${!IncID ? "disabled" : ""}`}
                                            data-toggle="modal"
                                            data-target={`#${tab.data_target}`}
                                            onClick={tab.target_function}
                                        >
                                            {tab.label}
                                        </button>
                                    </Nav.Item>
                                ) : (
                                    <Nav.Item key={tab.key}>
                                        <Nav.Link active={activeTab === tab.key}>
                                            <button
                                                type="button"
                                                className="btn p-0"
                                                onClick={() => setActiveTab(tab.key)}
                                                style={{ color: activeTab === tab.key ? "" : tab.class }}
                                            >
                                                {tab.label}
                                            </button>
                                        </Nav.Link>
                                    </Nav.Item>
                                )
                            ))}
                        </Nav>
                        {/* Tab Content */}
                        <div className="mt-1" ref={ActiveTabComponentRef}>
                            {ActiveTabComponent && <ActiveTabComponent />}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default CADDispatcher