import axios from "axios";
class MasterTableListServices {

    insertCFS = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/MasterCallforServiceCode/InsertCallforServiceCode`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.insertCFS");
        return null;
    };

    getCFS = async ({ queryKey }) => {
        const [_key, payload] = queryKey;
        if (payload) {
            return await axios.post(`/CAD/MasterCallforServiceCode/InsertCallforServiceCode`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.getCFS");
        return null;
    };

    getShift = async ({ queryKey }) => {
        const [_key, payloadShift] = queryKey;
        if (payloadShift) {
            return await axios.post(`/CAD/MasterResourceShift/GetData_Shift`, payloadShift);
        }
        console.warn("payload not provided, MasterTableListServices.getShift");
        return null;
    };

    getStationCode = async ({ queryKey }) => {
        const [_key, payloadStationCode] = queryKey;
        if (payloadStationCode) {
            return await axios.post(`/CAD/MasterStationCode/StationCode`, payloadStationCode);
        }
        console.warn("payload not provided, MasterTableListServices.GetStationCode");
        return null;
    };

    getResourceType = async ({ queryKey }) => {
        const [_key, payload] = queryKey;
        if (payload) {
            return await axios.post(`/CAD/MasterResourceType/GetResourceType`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.GetResourceType");
        return null;
    };

    getHospitalStatusCode = async ({ queryKey }) => {
        const [_key, payload] = queryKey;
        if (payload) {
            return await axios.post(`/CAD/MasterHospitalStatusCode/HospitalStatusCode`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.getHospitalStatusCode");
        return null;
    };

    insertHospitalStatusCode = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/MasterHospitalStatusCode/HospitalStatusCode`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.insertHospitalStatusCode");
        return null;
    };

    changeStatusHospitalStatusCode = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/MasterHospitalStatusCode/change_status`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.changeStatusHospitalStatusCode");
        return null;
    };

    deleteIncidentDispositions = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/MasterIncidentDispositions/DeleteIncidentDispositions`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.deleteIncidentDispositions");
        return null;
    };

    changeStatusBoloDisposition = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/MasterBoloDisposition/change_status`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.changeStatusBoloDisposition");
        return null;
    };

    changeStatusBoloType = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/MasterBoloType/change_status`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.changeStatusBoloType");
        return null;
    };

    getDataZone = async ({ queryKey }) => {
        const [_key, payload] = queryKey;
        if (payload) {
            return await axios.post(`/CAD/GeoPetrolZone/GetData_Zone`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.GetDataZone");
        return null;
    };

    getResources = async ({ queryKey }) => {
        const [_key, payload] = queryKey;
        if (payload) {
            return await axios.post(`/CAD/MasterResource/GetResource`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.getResources");
        return null;
    };

    getByIncidentResources = async ({ queryKey }) => {
        const [_key, payload] = queryKey;
        if (payload) {
            return await axios.post(`/CAD/CallTackerResource/GetData_Resources_ByIncidentId`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.getByIncidentResources");
        return null;
    };

    getIncidentDispositions = async ({ queryKey }) => {
        const [_key, payload] = queryKey;
        if (payload) {
            return await axios.post(`/CAD/MasterIncidentDispositions/GetData_IncidentDispositions`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.getIncidentDispositions");
        return null;
    };

    getBoloDisposition = async ({ queryKey }) => {
        const [_key, payload] = queryKey;
        if (payload) {
            return await axios.post(`/CAD/Monitor/MasterBoloDispositionGet`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.getBoloDisposition");
        return null;
    };

    getBoloType = async ({ queryKey }) => {
        const [_key, payload] = queryKey;
        if (payload) {
            return await axios.post(`/CAD/MasterBoloType/GetBoloType`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.getBoloType");
        return null;
    };

    getPrimaryOfficer = async ({ queryKey }) => {
        const [_key, payload] = queryKey;
        return await axios.post(`/CAD/MasterPrimaryOfficer/PrimaryOfficer`, payload);
    };

    getAgencyCode = async ({ queryKey }) => {
        const [_key] = queryKey;
        return await axios.post(`/CAD/MasterAgency/MasterAgencyCode`);
    };

    insertResource = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/MasterResource/upsertResource`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.InsertResource");
        return null;
    };

    updateResource = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/CallTackerResource/UpdateResource`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.updateResource");
        return null;
    };

    insertResourceType = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/MasterResourceType/InsertResourceType`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.insertResourceType");
        return null;
    };

    insertZone = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/GeoPetrolZone/InsertZone`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.insertZone");
        return null;
    };

    updateZone = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/GeoPetrolZone/UpdateZone`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.updateZone");
        return null;
    };

    changeStatusStationCode = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/MasterStationCode/change_status`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.changeStatusStationCode");
        return null;
    };

    changeStatusMasterResource = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/MasterResource/ChangeStatus`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.changeStatusMasterResource");
        return null;
    };

    // changeStatusMasterShift = async (payload) => {
    //     if (payload) {
    //         return await axios.post(`/CAD/MasterShift/change_status`, payload);
    //     }
    //     console.warn("payload not provided, MasterTableListServices.changeStatusMasterShift");
    //     return null;
    // };

    changeStatusAgencyCallFilter = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/MasterCFSagencycallfilter/change_status`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.changeStatusAgencyCallFilter");
        return null;
    };

    changeStatusResourceType = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/MasterResourceType/change_status`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.changeStatusResourceType");
        return null;
    };

    changeStatusCallforServiceCode = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/MasterCallforServiceCode/change_status`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.changeStatusCallforServiceCode");
        return null;
    };
    changeStatusZone = async (payload) => {
        if (payload) {
            return await axios.post(`CAD/GeoPetrolZone/change_status`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.changeStatusZone");
        return null;
    };

    getZone = async ({ queryKey }) => {
        const [_key, payload] = queryKey;
        if (payload) {
            return await axios.post(`/CAD/GeoPetrolZone/GetData_Zone`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.getZone");
        return null;
    };

    insertUpdateStationCode = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/MasterStationCode/StationCode`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.InsertResource");
        return null;
    };

    getCFSAgencyCallFilter = async ({ queryKey }) => {
        const [_key, payload] = queryKey;
        if (payload) {
            return await axios.post(`/CAD/MasterCFSagencycallfilter/GET_CFSagencycallfilter`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.getCFSAgencyCallFilter");
        return null;
    };

    insertCFSAgencyCallFilter = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/MasterCFSagencycallfilter/Insert_CFSagencycallfilter`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.insertCFSAgencyCallFilter");
        return null;
    };

    UpsertMiscellaneous = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/MasterMiscellaneous/UpsertMiscellaneous`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.UpsertMiscellaneous");
        return null;
    };

    getMiscellaneousStatus = async ({ queryKey }) => {
        const [_key, payload] = queryKey;
        if (payload) {
            return await axios.post(`/CAD/MasterMiscellaneous/GetMiscellaneousstatus`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.getMiscellaneousStatus");
        return null;
    };

    changeMiscellaneousStatus = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/MasterMiscellaneous/MiscellaneousStatus`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.changeMiscellaneousStatus");
        return null;
    };

    insertResourceStatusColor = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/ResourceStatusColor/InsertResourceStatusColor`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.insertResourceStatusColor");
        return null;
    };

    updateResourceStatusColor = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/ResourceStatusColor/UpdateResourceStatusColor`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.updateResourceStatusColor");
        return null;
    };

    getResourceStatusColor = async ({ queryKey }) => {
        const [_key, payload] = queryKey;
        if (payload) {
            return await axios.post(`/CAD/ResourceStatusColor/GetResourceStatusColor`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.getResourceStatusColor");
        return null;
    };

    changeStatusResourceStatusColor = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/ResourceStatusColor/change_status`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.changeStatusResourceStatusColor");
        return null;
    };

    insertMasterPriority = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/MasterPriority/InsertMasterPriority`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.insertMasterPriority");
        return null;
    };

    updateMasterPriority = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/MasterPriority/UpdateMasterPriority`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.updateMasterPriority");
        return null;
    };

    getMasterPriority = async ({ queryKey }) => {
        const [_key, payload] = queryKey;
        if (payload) {
            return await axios.post(`/CAD/MasterPriority/GetMasterPriority`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.getMasterPriority");
        return null;
    };

    getBolo = async ({ queryKey }) => {
        const [_key, payload] = queryKey;
        if (payload) {
            return await axios.post(`/CAD/Bolo/GetBolo`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.getBolo");
        return null;
    };

    changeStatusMasterPriority = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/MasterPriority/change_status`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.changeStatusMasterPriority");
        return null;
    };

    insertMasterDisposition = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/MasterIncidentDispositions/InsertIncidentDispositions`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.insertMasterDisposition");
        return null;
    };

    insertMasterBoloDisposition = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/Monitor/MasterBoloDispositionInsert`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.insertMasterBoloDisposition");
        return null;
    };
    insertBoloType = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/MasterBoloType/InsertBoloType`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.insertBoloType");
        return null;
    };

    updateMasterDisposition = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/MasterIncidentDispositions/UpdateIncidentDispositions`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.updateMasterDisposition");
        return null;
    };

    updateBoloDisposition = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/Monitor/MasterBoloDispositionUpdate`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.updateMasterDisposition");
        return null;
    };

    insertMasterOnOfDutyConfiguration = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/MasterOnOfDutyConfiguration/InsertMasterOnOfDutyConfiguration`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.insertMasterOnOfDutyConfiguration");
        return null;
    };

    updateMasterOnOfDutyConfiguration = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/MasterOnOfDutyConfiguration/UpdateMasterOnOfDutyConfiguration`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.updateMasterOnOfDutyConfiguration");
        return null;
    };

    getMasterOnOfDutyConfiguration = async ({ queryKey }) => {
        const [_key, payload] = queryKey;
        if (payload) {
            return await axios.post(`/CAD/MasterOnOfDutyConfiguration/GetMasterOnOfDutyConfiguration`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.getMasterOnOfDutyConfiguration");
        return null;
    };

    //Type of flag
    getTypeOfFlag = async ({ queryKey }) => {
        const [_key, payload] = queryKey;
        if (payload) {
            return await axios.post(`/CAD/MasterTypeOfFlag/TypeOfFlag`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.getTypeOfFlag");
        return null;
    };

    insertTypeOfFlag = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/MasterTypeOfFlag/TypeOfFlag`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.insertTypeOfFlag");
        return null;
    };

    changeStatusTypeOfFlag = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/MasterTypeOfFlag/change_status`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.changeStatusTypeOfFlag");
        return null;
    };

    getOperator = async ({ queryKey }) => {
        const [_key, payload] = queryKey;
        if (payload) {
            return await axios.post(`/CAD/Operator_search/Operator`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.getOperator");
        return null;
    };

}

const instance = new MasterTableListServices();

export default instance;
