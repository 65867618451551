import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import Dropdown from 'react-bootstrap/Dropdown';
import { Filter, SendIcon } from '../../../Components/Filter/Filter';
import { tableCustomStyles } from '../../../Components/Common/Utility';
import CADConfirmModal from '../../Common/CADConfirmModal';
import { compareStrings, isEmpty } from '../../../CADUtils/functions/common';
import Select from "react-select";
import { coloredStyle_Select } from '../../Utility/CustomStylesForReact';
import useObjState from '../../../CADHook/useObjState';
import MasterTableListServices from "../../../CADServices/APIs/masterTableList";
import { toastifyError, toastifySuccess } from '../../../Components/Common/AlertMsg';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
function BoloDispositionSection() {
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const [filterTypeIdOption, setFilterTypeIdOption] = useState('Contains');
    const [pageStatus, setPageStatus] = useState("1");
    const [filterTypeDescOption, setFilterTypeDescOption] = useState('Contains');
    const [searchValue1, setSearchValue1] = useState('');
    const [searchValue2, setSearchValue2] = useState('');
    const [filterListData, setFilterListData] = useState([]);
    const [listData, setListData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isActive, setIsActive] = useState('');
    const [activeInactiveData, setActiveInactiveData] = useState({})
    const [agencyCodeDropDown, setAgencyCodeDropDown] = useState([])
    const [confirmType, setConfirmType] = useState('');
    const [loginPinID, setLoginPinID] = useState(1);

    const [
        boloDispositionState,
        setBoloDispositionState,
        handleBoloDispositionState,
        clearBoloDispositionState,
    ] = useObjState({
        code: "",
        agencyCode: "",
        agencyType1: "",
        agencyType2: "",
        description: "",
        IsActive: 1
    })

    const [
        errorBoloDispositionState,
        _setErrorBoloDispositionState,
        handleErrorBoloDispositionState,
        clearErrorBoloDispositionState, ,
    ] = useObjState({
        code: false,
        agencyCode: false,
        description: false,
    });


    const getAgencyCodeKey = `/CAD/MasterAgency/MasterAgencyCode`;
    const { data: getAgencyCodeData, isSuccess: isFetchAgencyCode } = useQuery(
        [getAgencyCodeKey, {},],
        MasterTableListServices.getAgencyCode,
        {
            refetchOnWindowFocus: false,
        }
    );

    const getBoloDispositionKey = `/CAD/Monitor/MasterBoloDispositionGet/${parseInt(pageStatus)}`;
    const { data: getBoloDispositionData, isSuccess: isFetchBoloDisposition, refetch, isError: isNoData } = useQuery(
        [getBoloDispositionKey, {
            "IsActive": parseInt(pageStatus),
        },],
        MasterTableListServices.getBoloDisposition,
        {
            refetchOnWindowFocus: false,
            retry: 0
        }
    );

    useEffect(() => {
        if (getBoloDispositionData && isFetchBoloDisposition) {
            const data = JSON.parse(getBoloDispositionData?.data?.data);
            setFilterListData(data?.Table)
            setListData(data?.Table)
        } else {
            setFilterListData([])
            setListData([])
        }
    }, [getBoloDispositionData, isFetchBoloDisposition])


    useEffect(() => {
        if (isFetchAgencyCode && getAgencyCodeData) {
            const data = JSON.parse(getAgencyCodeData?.data?.data);
            setAgencyCodeDropDown(data?.Table || [])
        }
    }, [isFetchAgencyCode, getAgencyCodeData]);

    useEffect(() => {
        if (localStoreData) {
            setLoginPinID(localStoreData?.PINID);
        }
    }, [localStoreData]);

    const columns = [
        {
            name: 'Code',
            selector: row => row.DispositionCode,
            sortable: true,
            sortFunction: (rowA, rowB) => compareStrings(rowA.DispositionCode, rowB.DispositionCode),
            width: "20%",
            style: {
                position: "static",
            },
        },
        {
            name: 'Description',
            selector: row => row.Description,
            sortable: true,
            sortFunction: (rowA, rowB) => compareStrings(rowA.Description, rowB.Description),
            style: {
                position: "static",
            },
        },
        {
            name: 'Agency Code',
            selector: row => row.AgencyCode,
            sortable: true,
            sortFunction: (rowA, rowB) => compareStrings(rowA.AgencyCode, rowB.AgencyCode),
            style: {
                position: "static",
            },
        },
        {
            name:
                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    {'Status'}
                </div>,
            cell: (row, index) =>
                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <span
                        className="btn btn-sm text-white px-1 py-0 mr-1"
                        style={{ background: "#ddd", cursor: "pointer" }}
                    >
                        {row?.IsActive ? (
                            <i className="fa fa-toggle-on" style={{ color: "green" }} aria-hidden="true" onClick={(e) => { setShowModal(true); setActiveInactiveData(row); setIsActive(0); setConfirmType("InActive"); handleClose() }}></i>
                        ) : (
                            <i className="fa fa-toggle-off" style={{ color: "red" }} aria-hidden="true" onClick={(e) => { setShowModal(true); setActiveInactiveData(row); setIsActive(1); setConfirmType("Active"); handleClose() }}></i>
                        )}
                    </span>
                </div>,
            width: "70px",
            style: {
                position: "static",
            },
        },
    ];

    function handleClose() {
        clearBoloDispositionState();
        clearErrorBoloDispositionState();
    }

    async function handelActiveInactive() {
        const data = {
            "BoloDispositionID": activeInactiveData?.BoloDispositionID,
            "IsActive": isActive,
        }
        const response = await MasterTableListServices.changeStatusBoloDisposition(data);
        if (response?.status === 200) {
            const data = JSON.parse(response?.data?.data)?.Table?.[0];
            toastifySuccess(data?.Message);
            refetch();
        }
        setShowModal(false);
        handleClose();
    }

    const validation = () => {
        let isError = false;
        const keys = Object.keys(errorBoloDispositionState);
        keys.forEach((field) => {
            if (
                field === "agencyCode" &&
                isEmpty(boloDispositionState[field])) {
                handleErrorBoloDispositionState(field, true);
                isError = true;
            } else if (field === "code" && isEmpty(boloDispositionState[field])) {
                handleErrorBoloDispositionState(field, true);
                isError = true;
            } else if (field === "description" && isEmpty(boloDispositionState[field])) {
                handleErrorBoloDispositionState(field, true);
                isError = true;
            } else {
                handleErrorBoloDispositionState(field, false);
            }
        });
        return !isError;
    };

    async function handleSave() {
        if (!validation()) return;
        const isUpdate = !!boloDispositionState?.BoloDispositionID;

        const result = listData?.find(item => {
            if (item.DispositionCode) {
                const code = boloDispositionState?.code?.toLowerCase();
                return code && item.DispositionCode.toLowerCase() === code;
            }
            return false;
        });
        const result1 = listData?.find(item => {
            if (item.Description) {
                const description = boloDispositionState?.description?.toLowerCase();
                return description && item.Description.toLowerCase() === description;
            }
            return false;
        });
        if ((result || result1) && !isUpdate) {
            if (result) {
                toastifyError('Code Already Exists');
            }
            if (result1) {
                toastifyError('Description Already Exists');
            }
        } else {
            const data = {
                AgencyID: boloDispositionState?.agencyCode,
                DispositionCode: boloDispositionState?.code,
                BoloDispositionID: isUpdate ? boloDispositionState?.BoloDispositionID : undefined,
                Description: boloDispositionState?.description,
                CreatedByUserFK: isUpdate ? undefined : loginPinID,
                ModifiedByUserFK: isUpdate ? loginPinID : undefined,
                IsActive: boloDispositionState?.IsActive ? 1 : 0,
            };
            let response;
            if (isUpdate) {
                response = await MasterTableListServices.updateBoloDisposition(data);
            } else {
                response = await MasterTableListServices.insertMasterBoloDisposition(data);
            }
            if (response?.status === 200) {
                toastifySuccess(isUpdate ? "Data Updated Successfully" : "Data Saved Successfully");
                handleClose();
                refetch();
            }
        }
    }


    function handelSetEditData(data) {
        setBoloDispositionState({
            BoloDispositionID: data?.BoloDispositionID,
            code: data?.DispositionCode,
            agencyCode: data?.AgencyID,
            description: data?.Description,
            IsActive: data?.IsActive
        })
    }

    const conditionalRowStyles = [
        {
            when: row => row?.BoloDispositionID === boloDispositionState?.BoloDispositionID,
            style: {
                backgroundColor: '#001f3fbd',
                color: 'white',
                cursor: 'pointer',
                '&:hover': {
                    backgroundColor: '#001f3fbd',
                    color: 'white',
                },
            },
        }
    ];

    const handleTabClick = (status) => {
        setPageStatus(status);
        setSearchValue1("");
        setSearchValue2("");
        handleClose();
    };


    return (
        <>
            <div className='utilities-tab-content-main-container'>
                <div className='utilities-tab-content-form-container'>
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-12 incident-tab ">
                            <ul className="nav nav-tabs mb-1 pl-2" id="myTab" role="tablist">
                                <li
                                    className={`nav-item ${pageStatus === '1' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('1')}
                                    role="tab"
                                    aria-selected={pageStatus === '1'}
                                    style={{ color: pageStatus === '1' ? 'Red' : '' }}
                                >
                                    Active
                                </li>
                                <li
                                    className={`nav-item ${pageStatus === '0' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('0')}
                                    role="tab"
                                    aria-selected={pageStatus === '0'}
                                    style={{ color: pageStatus === '0' ? 'Red' : '' }}
                                >
                                    Inactive
                                </li>
                            </ul>
                        </div>
                        {
                            pageStatus === '1' ?
                                <>
                                    <div className='utilities-tab-content-form-main'>
                                        <div className="row">
                                            <div className="col-2 d-flex align-self-center justify-content-end">
                                                <label htmlFor="" className="tab-form-label">
                                                    Agency Code  {errorBoloDispositionState.agencyCode && isEmpty(boloDispositionState?.agencyCode) && (
                                                        <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Select Agency"}</p>
                                                    )}
                                                </label>
                                            </div>
                                            <div className="col-3 d-flex align-self-center justify-content-end">
                                                <Select
                                                    isClearable
                                                    styles={coloredStyle_Select}
                                                    placeholder="Select"
                                                    value={boloDispositionState?.agencyCode ? agencyCodeDropDown?.find((i) => i?.AgencyID === parseInt(boloDispositionState?.agencyCode)) : ""}
                                                    options={agencyCodeDropDown}
                                                    getOptionLabel={(v) => v?.ShortName}
                                                    getOptionValue={(v) => v?.AgencyID}
                                                    onChange={(e) => { handleBoloDispositionState("agencyCode", e?.AgencyID) }}
                                                    onInputChange={(inputValue, actionMeta) => {
                                                        if (inputValue.length > 12) {
                                                            return inputValue.slice(0, 12);
                                                        }
                                                        return inputValue;
                                                    }}
                                                />
                                            </div>
                                            <div className="col-2 d-flex align-self-center justify-content-end">
                                                <label htmlFor="" className="tab-form-label">
                                                    Agency Type
                                                </label>
                                            </div>
                                            <div className="col-1">
                                                <input
                                                    type="text"
                                                    className="form-control py-1 new-input"
                                                    placeholder="Code"
                                                    name="AgencyType1"
                                                    readOnly
                                                    value={boloDispositionState.AgencyType1}
                                                    style={{ flex: '1' }}
                                                />
                                            </div>
                                            <div className="col-2">
                                                <input
                                                    type="text"
                                                    className="form-control py-1 ml-2 new-input"
                                                    placeholder="Description"
                                                    name="AgencyType2"
                                                    readOnly
                                                    value={boloDispositionState.AgencyType2}
                                                    style={{ flex: '1' }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-2 d-flex align-self-center justify-content-end">
                                                <label htmlFor="" className="tab-form-label">
                                                    Code
                                                    {errorBoloDispositionState.code && isEmpty(boloDispositionState?.code) && (
                                                        <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Enter Code"}</p>
                                                    )}
                                                </label>
                                            </div>
                                            <div className="col-3 d-flex align-self-center justify-content-end">
                                                <input
                                                    name="code"
                                                    type="text"
                                                    className="form-control py-1 new-input requiredColor"
                                                    placeholder='Code'
                                                    value={boloDispositionState?.code}
                                                    onChange={(e) => handleBoloDispositionState("code", e.target.value)}
                                                />
                                            </div>
                                            <div className="col-2 d-flex align-self-center justify-content-end">
                                                <label htmlFor="" className="tab-form-label">
                                                    Description
                                                    {errorBoloDispositionState.description && isEmpty(boloDispositionState?.description) && (
                                                        <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Enter Description"}</p>
                                                    )}
                                                </label>
                                            </div>
                                            <div className="col-4 d-flex align-self-center justify-content-end">
                                                <input
                                                    name="description"
                                                    type="text"
                                                    className="form-control py-1 new-input requiredColor"
                                                    placeholder='Description'
                                                    value={boloDispositionState?.description}
                                                    onChange={(e) => handleBoloDispositionState("description", e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                </>
                        } </div>

                </div>

                <div className='utilities-tab-content-table-container'>
                    <div className="row mb-2">
                        <div className="col-5 d-flex align-self-center justify-content-end">
                            <input
                                type="text"
                                className="form-control"
                                placeholder='Search By Code...'
                                value={searchValue1}
                                onChange={(e) => {
                                    setSearchValue1(e.target.value);
                                    const result = Filter(listData, e.target.value, searchValue2, filterTypeIdOption, 'DispositionCode', 'Description')
                                    setFilterListData(result)
                                }}
                            />
                        </div>
                        <div className="col-1 d-flex align-self-center justify-content-end" >
                            <Dropdown className='w-100'>
                                <Dropdown.Toggle id="dropdown-basic" className='cad-sort-dropdown'>
                                    <img src={SendIcon(filterTypeIdOption)} alt="" className='filter-icon mr-1' />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => setFilterTypeIdOption('Contains')}>Contains</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setFilterTypeIdOption('is equal to')}>is equal to</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setFilterTypeIdOption('is not equal to')}>is not equal to </Dropdown.Item>
                                    <Dropdown.Item onClick={() => setFilterTypeIdOption('Starts With')}>Starts With</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setFilterTypeIdOption('End with')}>End with</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="col-5 d-flex align-self-center justify-content-end">
                            <input
                                type="text"
                                className="form-control"
                                placeholder='Search By Description...'
                                value={searchValue2}
                                onChange={(e) => {
                                    setSearchValue2(e.target.value);
                                    const result = Filter(listData, searchValue1, e.target.value, filterTypeDescOption, 'DispositionCode', 'Description')
                                    setFilterListData(result)
                                }}
                            />
                        </div>

                        <div className="col-1 d-flex align-self-center justify-content-end" style={{ zIndex: '1', }}>
                            <Dropdown className='w-100'>
                                <Dropdown.Toggle id="dropdown-basic" className='cad-sort-dropdown'>
                                    <img src={SendIcon(filterTypeDescOption)} alt="" className='filter-icon mr-1' />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => setFilterTypeDescOption('Contains')}>Contains</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setFilterTypeDescOption('is equal to')}>is equal to</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setFilterTypeDescOption('is not equal to')}>is not equal to </Dropdown.Item>
                                    <Dropdown.Item onClick={() => setFilterTypeDescOption('Starts With')}>Starts With</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setFilterTypeDescOption('End with')}>End with</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>

                    <div className="table-responsive">
                        <DataTable
                            dense
                            columns={columns}
                            data={filterListData}
                            customStyles={tableCustomStyles}
                            conditionalRowStyles={conditionalRowStyles}
                            pagination
                            responsive
                            noDataComponent={isNoData ? "There are no data to display" : 'There are no data to display'}
                            striped
                            persistTableHead={true}
                            highlightOnHover
                            fixedHeader
                            onRowClicked={(row) => {
                                handelSetEditData(row);
                            }}
                        />
                    </div>
                    {parseInt(pageStatus) === 1 &&
                        <div className="utilities-tab-content-button-container" >
                            <button type="button" className="btn btn-sm btn-success" onClick={() => handleClose()} >New</button>
                            <button type="button" className="btn btn-sm btn-success" onClick={() => handleSave()} >{!!boloDispositionState?.BoloDispositionID ? "Update" : "Save"}</button>
                        </div>}
                </div>
            </div>
            <CADConfirmModal showModal={showModal} setShowModal={setShowModal} handleConfirm={handelActiveInactive} confirmType={confirmType} />
        </>
    )
}

export default BoloDispositionSection