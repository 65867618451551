import React, { useContext, useEffect, useRef, useState } from 'react'
import useObjState from '../../CADHook/useObjState';
import Select from "react-select";
import DatePicker from "react-datepicker";
import { colorLessStyle_Select } from '../Utility/CustomStylesForReact';
import { getShowingWithOutTime } from '../../Components/Common/Utility';
import { useQuery } from 'react-query';
import CallTakerServices from "../../CADServices/APIs/callTaker";
import MasterTableListServices from "../../CADServices/APIs/masterTableList";
import { useSelector } from 'react-redux';
import { IncidentContext } from '../../CADContext/Incident';
import Location from '../Common/Location';
import GeoServices from "../../CADServices/APIs/geo";
import { fetchPostData } from '../../Components/hooks/Api';
import { toastifyError } from '../../Components/Common/AlertMsg';
import { useNavigate } from 'react-router-dom';
import { AgencyContext } from '../../Context/Agency/Index';
import moment from 'moment';

function EventSearchPage() {
  const navigate = useNavigate();
  const { setEventData, } = useContext(AgencyContext);
  const localStoreData = useSelector((state) => state.Agency.localStoreData);
  const { resourceData } = useContext(IncidentContext);
  const [loginAgencyID, setLoginAgencyID] = useState("");
  const [loginPinID, setLoginPinID] = useState(1);
  const [receiveSourceDropDown, setReceiveSourceDropDown] = useState([]);
  const [shiftDropDown, setShiftDropDown] = useState([])
  const [cfsDropDown, setCFSDropDown] = useState([]);
  const [operatorDropDown, setOperatorDropDown] = useState([]);
  const [flagData, setFlagData] = useState([]);
  const [locationStatus, setLocationStatus] = useState(false);
  const [locationData, setLocationData] = useState();
  const [isSelectLocation, setIsSelectLocation] = useState(false);
  const [IsChangeData, setIsChangeData] = useState(false);

  const [
    eventState,
    setEventState,
    handleEventState,
    clearEventState,
  ] = useObjState({
    eventSearch: "bothEvents",
    agencyCode: "",
    dateFilter: "none",
    reportedFromDate: "",
    reportedToDate: "",
    reportedFromTime: "",
    RMSIncidentFrom: "",
    RMSIncidentTo: "",
    reportedToTime: "",
    receiveSource: "",
    CADEventFrom: "",
    CADEventTo: "",
    reportedCSF: "",
    operatorID: "",
    foundCSF: "",
    resourceType: "",
    resource: null,
    primaryUnit: null,
    Comments: "",
    flag: null,
    location: "",
    apt: "",
    intersectionSt: "",
    intersectionSt1: "",
    city: "",
    commonPlaceName: "",
    zip: "",
    primaryResource: null,
    shift: "",
    primaryOfficer: null
  });


  const receiveSourceKey = `/CAD/CallTakerReceiveSource/GetData_ReceiveSource`;
  const { data: receiveSourceData, isSuccess: isFetchReceiveSourceData } =
    useQuery(
      [receiveSourceKey, { Action: "GetData_ReceiveSource" }],
      CallTakerServices.getReceiveSource,
      {
        refetchOnWindowFocus: false,
        enabled: !!loginAgencyID,
      }
    );

  const CFSCodeKey = `/CAD/MasterCallforServiceCode/InsertCallforServiceCode`;
  const { data: cfsData, isSuccess: isFetchCFSData } = useQuery(
    [
      CFSCodeKey,
      {
        Action: "GET",
        IsActive: 1
      }
    ],
    MasterTableListServices.getCFS,
    {
      refetchOnWindowFocus: false,
    }
  );

  const GetResourceTypeKey = `/CAD/MasterResourceType/GetResourceType`;
  const { data: resourceTypeData } = useQuery(
    [GetResourceTypeKey, { Action: "GET", IsActive: 1 },],
    MasterTableListServices.getResourceType,
    {
      refetchOnWindowFocus: false,
    }
  );

  const shiftDataKey = `/CAD/MasterResourceShift/GetData_Shift`;
  const { data: shiftData, isSuccess: isFetchShiftData } = useQuery(
    [shiftDataKey, { AgencyID: loginAgencyID },],
    MasterTableListServices.getShift,
    {
      refetchOnWindowFocus: false,
      enabled: !!loginAgencyID
    }
  );

  const getTypeOfFlagKey = `/CAD/MasterTypeOfFlag/TypeOfFlag`;
  const { data: getTypeOfFlagData, isSuccess: isFetchGetTypeOfFlag } = useQuery(
    [getTypeOfFlagKey, {
      "Action": "GET",
      "IsActive": 1,
      "AgencyID": loginAgencyID,
    },],
    MasterTableListServices.getTypeOfFlag,
    {
      refetchOnWindowFocus: false,
      retry: 0,
      enabled: !!loginAgencyID
    }
  );
  const getOperatorKey = `/CAD/Operator_search/Operator`;
  const { data: getOperatorData, isSuccess: isFetchGetOperator } = useQuery(
    [getOperatorKey, {
      "AgencyID": loginAgencyID,
    },],
    MasterTableListServices.getOperator,
    {
      refetchOnWindowFocus: false,
      retry: 0,
      enabled: !!loginAgencyID
    }
  );

  useEffect(() => {
    if (!eventState?.location) {
      setEventState((prevState) => ({
        ...prevState,
        Id: "",
      }));
    }

    const fetchLocationData = async () => {
      try {
        const response = await GeoServices.getLocationData({
          Location: eventState?.location,
        });
        const data = JSON.parse(response?.data?.data)?.Table || [];
        setLocationData(data);

      } catch (error) {
        console.error("Error fetching location data:", error);
        setLocationData([]);
      }
    };

    if (eventState?.location) {
      fetchLocationData();
    }
  }, [eventState?.location, isSelectLocation]);


  useEffect(() => {
    if (getOperatorData && isFetchGetOperator) {
      const data = JSON.parse(getOperatorData?.data?.data);
      setOperatorDropDown(data?.Table)
    } else {
      setOperatorDropDown([])
    }
  }, [getOperatorData, isFetchGetOperator])

  useEffect(() => {
    if (getTypeOfFlagData && isFetchGetTypeOfFlag) {
      const data = JSON.parse(getTypeOfFlagData?.data?.data);
      setFlagData(data?.Table)
    } else {
      setFlagData([])
    }
  }, [getTypeOfFlagData, isFetchGetTypeOfFlag])

  useEffect(() => {
    if (shiftData && isFetchShiftData) {
      const data = JSON.parse(shiftData?.data?.data);
      setShiftDropDown(data?.Table || [])
    }
  }, [shiftData, isFetchShiftData])

  useEffect(() => {
    if (localStoreData) {
      setLoginPinID(localStoreData?.PINID);
      setLoginAgencyID(localStoreData?.AgencyID);
    }
  }, [localStoreData]);

  useEffect(() => {
    if (isFetchCFSData && cfsData) {
      const data = JSON.parse(cfsData?.data?.data)?.Table;
      setCFSDropDown(data);
    }
  }, [isFetchCFSData, cfsData]);

  useEffect(() => {
    if (isFetchReceiveSourceData && receiveSourceData) {
      const data = JSON.parse(receiveSourceData?.data?.data);
      setReceiveSourceDropDown(data?.Table || []);
    }
  }, [isFetchReceiveSourceData, receiveSourceData]);

  const startRef = useRef();
  const onKeyDown = (e) => {
    if (e.keyCode === 9 || e.which === 9) {
      startRef.current.setOpen(false);
    }
  };

  const getEventDateRange = (dateFilter) => {
    const currentDate = new Date();
    let startDate = null;

    switch (dateFilter) {
      case "24hr":
        startDate = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000);
        break;
      case "lastWeek":
        startDate = new Date();
        startDate.setDate(currentDate.getDate() - 7);
        startDate.setHours(0, 0, 0, 0);
        break;
      case "lastMonth":
        startDate = new Date();
        startDate.setMonth(currentDate.getMonth() - 1);
        startDate.setHours(0, 0, 0, 0);
        break;
      case "lastYear":
        startDate = new Date();
        startDate.setFullYear(currentDate.getFullYear() - 1);
        startDate.setHours(0, 0, 0, 0);
        break;
      default:
        return "";
    }

    return startDate.toISOString().split("T")[0];
  };

  async function handleSearch() {
    const payload = {
      "AgencyID": loginAgencyID,
      "IncidentNumber": eventState?.RMSIncidentFrom,
      "IncidentNumberTo": eventState?.RMSIncidentTo,
      "ReportedDateTO": eventState?.reportedToDate ? moment(eventState?.reportedToDate).format('YYYY/MM/DD') : "",
      "ReportedDate": eventState?.reportedFromDate ? moment(eventState?.reportedFromDate).format('YYYY/MM/DD') : "",
      "ReceiveSourceID": eventState?.receiveSource?.ReceiveSourceID,
      "CADIncidentNumberFrom": eventState?.CADEventFrom,
      "CADIncidentNumberTo": eventState?.CADEventTo,
      "CrimeLocation": eventState?.location,
      "ReportedCFSCodeID": eventState?.reportedCSF?.CallforServiceID,
      "FoundCFSCodeID": eventState?.foundCSF?.CallforServiceID,
      "EventDate": getEventDateRange(eventState?.dateFilter),
      "ResourceNumber": eventState?.resource?.map(r => r?.ResourceID).join(","),
      "ResourceType": eventState?.resourceType?.ResourceTypeID,
      "Flag": eventState?.flag?.map(f => f?.FlagID).join(","),
      "Shift": eventState?.shift?.ShiftId,
      "City": eventState?.city,
      "InterDirectionPrefix": eventState?.intersectionSt,
      "InterDirectionSufix": eventState?.intersectionSt1,
      "CommonPlace": eventState?.commonPlaceName,
      "ZipCode": eventState?.zip,
      "ApartmentNo": eventState?.apt,
      "ActiveClosedEvents": eventState?.eventSearch === "bothEvents" ? null : eventState?.eventSearch === "activeEvents" ? 1 : 0,
      "OperatorID": eventState?.operatorID?.length > 0 ? eventState?.operatorID?.map(o => o?.PINID).join(",") : "",
      "Comments": eventState?.Comments,
      "PrimeryOfficer": eventState?.primaryOfficer?.map(o => o?.PINID).join(","),
      "PrimeryUnit": eventState?.primaryUnit?.map(pu => pu?.ResourceID).join(","),
      "ReportedFromTime": eventState?.reportedFromTime ? moment(eventState?.reportedFromTime).format('HH:mm') : "",
      "ReportedToTime": eventState?.reportedToTime ? moment(eventState?.reportedToTime).format('HH:mm') : "",
    };

    if (loginAgencyID) {
      fetchPostData("/CAD/QueryIncident/IncidentEventSearch", payload).then((res) => {
        if (res.length > 0) {
          setEventData(res);
          navigate('/cad/eventSearch');
          clearEventState();
        } else {
          setEventData([]);
          toastifyError("Data Not Available");
        }
      });
    } else {
      toastifyError("Please Enter Details");
    }
  }

  const customStylesWithOutColorArrow = {
    control: base => ({
      ...base,
      // height: 20,
      minHeight: 35,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      transition: "all .2s ease",
      transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null
    }),
    input: (provided) => ({
      ...provided,
      minWidth: '0px',
      maxWidth: '100%',
    }),
  };

  return (
    <div className="section-body pt-1 p-1 bt" >
      <div className="div">
        <div className="dark-row" >
          <div className="col-12 col-sm-12">
            <div className="card Agency ">
              <div className="card-body" >
                <div className="row " style={{ marginTop: '-10px' }}>
                  <div className="col-12 row my-2">
                    <div className="d-flex align-self-center justify-content-start" style={{ width: '120px' }}>
                      <div className='d-flex align-self-center justify-content-start' style={{ gap: '5px' }}>
                        <input type="radio" id="bothEvents" value="bothEvents" checked={eventState?.eventSearch === 'bothEvents'} onChange={(e) => { handleEventState("eventSearch", e.target.value); }} />
                        <label for="bothEvents" className='tab-form-label' style={{ margin: '0', }}>Both Events</label>
                      </div>
                    </div>
                    <div className="d-flex align-self-center justify-content-start" style={{ width: '120px' }}>
                      <div className='d-flex align-self-center justify-content-start' style={{ gap: '5px' }}>
                        <input type="radio" id="activeEvents" value="activeEvents" checked={eventState?.eventSearch === 'activeEvents'} onChange={(e) => {
                          handleEventState("eventSearch", e.target.value)
                        }} />
                        <label for="activeEvents" className='tab-form-label' style={{ margin: '0', }}>Active Events</label>
                      </div>
                    </div>
                    <div className="d-flex align-self-center justify-content-start" style={{ width: '120px' }}>
                      <div className='d-flex align-self-center justify-content-start' style={{ gap: '5px' }}>
                        <input type="radio" id="closedEvents" value="closedEvents" checked={eventState?.eventSearch === 'closedEvents'} onChange={(e) => {
                          handleEventState("eventSearch", e.target.value)
                        }} />
                        <label for="closedEvents" className='tab-form-label' style={{ margin: '0', }}>Closed Events</label>
                      </div>
                    </div>
                    <div className="d-flex align-self-center justify-content-start" >
                      <div className='d-flex align-items-center justify-content-start' style={{ gap: '5px' }}>
                        <label for="closedEvents" className='tab-form-label text-nowrap'>Select Agency Type</label>
                        <Select
                          styles={colorLessStyle_Select}
                          placeholder="Select"
                          options={[]}
                          getOptionLabel={(v) => v?.ShortName}
                          getOptionValue={(v) => v?.AgencyID}
                          isClearable
                          value={eventState?.agencyCode}
                          onChange={(e) => { handleEventState("agencyCode", e) }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <fieldset>
                      <legend>Event Search</legend>
                      <div className="row align-self-center justify-content-center px-1 mt-4 mb-2">
                        <div className="d-flex align-self-center justify-content-center" style={{ width: '120px' }}>
                          <div className='d-flex align-self-center justify-content-start' style={{ gap: '5px' }}>
                            <input type="radio" id="none" value="none" checked={eventState?.dateFilter === 'none'} onChange={(e) => { handleEventState("dateFilter", e.target.value); }} />
                            <label for="none" className='tab-form-label' style={{ margin: '0', }}>None</label>
                          </div>
                        </div>
                        <div className="d-flex align-self-center justify-content-start" style={{ width: '90px' }}>
                          <div className='d-flex align-self-center justify-content-start' style={{ gap: '5px' }}>
                            <input type="radio" id="24hr" value="24hr" checked={eventState?.dateFilter === '24hr'} onChange={(e) => {
                              handleEventState("dateFilter", e.target.value)
                            }} />
                            <label for="24hr" className='tab-form-label' style={{ margin: '0', }}>24Hr</label>
                          </div>
                        </div>
                        <div className="d-flex align-self-center justify-content-start" style={{ width: '120px' }}>
                          <div className='d-flex align-self-center justify-content-start' style={{ gap: '5px' }}>
                            <input type="radio" id="lastWeek" value="lastWeek" checked={eventState?.dateFilter === 'lastWeek'} onChange={(e) => {
                              handleEventState("dateFilter", e.target.value)
                            }} />
                            <label for="lastWeek" className='tab-form-label' style={{ margin: '0', }}>Last Week</label>
                          </div>
                        </div>
                        <div className="d-flex align-self-center justify-content-start" style={{ width: '120px' }}>
                          <div className='d-flex align-self-center justify-content-start' style={{ gap: '5px' }}>
                            <input type="radio" id="lastMonth" value="lastMonth" checked={eventState?.dateFilter === 'lastMonth'} onChange={(e) => {
                              handleEventState("dateFilter", e.target.value)
                            }} />
                            <label for="lastMonth" className='tab-form-label' style={{ margin: '0', }}>Last Month</label>
                          </div>
                        </div>
                        <div className="d-flex align-self-center justify-content-start" style={{ width: '120px' }}>
                          <div className='d-flex align-self-center justify-content-start' style={{ gap: '5px' }}>
                            <input type="radio" id="lastYear" value="lastYear" checked={eventState?.dateFilter === 'lastYear'} onChange={(e) => {
                              handleEventState("dateFilter", e.target.value)
                            }} />
                            <label for="lastYear" className='tab-form-label' style={{ margin: '0', }}>Last Year</label>
                          </div>
                        </div>
                      </div>
                      <div className='row mb-1'>
                        <div className="col-2 d-flex align-self-center justify-content-end">
                          <label for="" className="tab-form-label">
                            Reported From Date
                          </label>
                        </div>
                        <div className="col-2 d-flex align-self-center justify-content-end">
                          <DatePicker
                            name='startDate'
                            id='startDate'
                            onChange={(v) => handleEventState("reportedFromDate", v)}
                            selected={eventState?.reportedFromDate || ""}
                            dateFormat="MM/dd/yyyy"
                            isClearable={!!eventState?.reportedFromDate}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            autoComplete="off"
                            placeholderText="Select From Date..."
                            maxDate={eventState?.reportedToDate || null}
                          />
                        </div>
                        <div className="col-2 d-flex align-self-center justify-content-end">
                          <label for="" className="tab-form-label">
                            Reported To Date
                          </label>
                        </div>
                        <div className="col-2 d-flex align-self-center justify-content-end">
                          <DatePicker
                            name='reportedToDate'
                            id='reportedToDate'
                            onChange={(v) => handleEventState("reportedToDate", v)}
                            selected={eventState?.reportedToDate || ""}
                            dateFormat="MM/dd/yyyy"
                            isClearable={!!eventState?.reportedToDate}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            autoComplete="off"
                            placeholderText="Select To Date..."
                            minDate={eventState?.reportedFromDate}
                            maxDate={new Date()}
                          />
                        </div>
                      </div>
                      <div className='col-12 mb-1'>
                        <div className='row'>
                          <div className="col-2 d-flex align-self-center justify-content-end">
                            <label for="" className="tab-form-label">
                              Reported From Time
                            </label>
                          </div>
                          <div className="col-2 d-flex align-self-center justify-content-end">
                            <DatePicker
                              selected={eventState?.reportedFromTime}
                              onChange={(e) => handleEventState("reportedFromTime", e)}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={1}
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                              isClearable={!!eventState?.reportedFromTime}
                              placeholderText="Select Time"
                            />
                          </div>
                          <div className="col-2 d-flex align-self-center justify-content-end">
                            <label for="" className="tab-form-label">
                              Reported To Time
                            </label>
                          </div>
                          <div className="col-2 d-flex align-self-center justify-content-end">
                            <DatePicker
                              selected={eventState?.reportedToTime}
                              onChange={(e) => handleEventState("reportedToTime", e)}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={1}
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                              isClearable={!!eventState?.reportedToTime}
                              placeholderText="Select Time"
                            />
                          </div>
                          <div className="col-2 d-flex align-self-center justify-content-end">
                            <label for="" className="tab-form-label">
                              Receive Source
                            </label>
                          </div>
                          <div className="col-2 d-flex align-self-center justify-content-end">
                            <Select
                              styles={colorLessStyle_Select}
                              placeholder="Select"
                              options={receiveSourceDropDown}
                              getOptionLabel={(v) => v?.ReceiveSourceCode}
                              getOptionValue={(v) => v?.ReceiveSourceID}
                              isClearable
                              onInputChange={(inputValue, actionMeta) => {
                                if (inputValue.length > 12) {
                                  return inputValue.slice(0, 12);
                                }
                                return inputValue;
                              }}
                              value={eventState?.receiveSource}
                              onChange={(e) => { handleEventState("receiveSource", e) }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-12 mb-1'>
                        <div className='row'>
                          <div className="col-2 d-flex align-self-center justify-content-end">
                            <label for="" className="tab-form-label">
                              CAD Event # From
                            </label>
                          </div>
                          <div className="col-2 d-flex align-self-center justify-content-end">
                            <input
                              type="text"
                              className="form-control  py-1 new-input"
                              name="CADEventFrom"
                              placeholder="CAD Event # From"
                              value={eventState.CADEventFrom}
                              onChange={(e) => handleEventState("CADEventFrom", e.target.value)}
                            />
                          </div>
                          <div className="col-2 d-flex align-self-center justify-content-end">
                            <label for="" className="tab-form-label">
                              CAD Event # To
                            </label>
                          </div>
                          <div className="col-2 d-flex align-self-center justify-content-end">
                            <input
                              type="text"
                              className="form-control  py-1 new-input"
                              name="CADEventTo"
                              placeholder="CAD Event # To"
                              value={eventState.CADEventTo}
                              onChange={(e) => handleEventState("CADEventTo", e.target.value)}
                            />
                          </div>
                          <div className="col-2 d-flex align-self-center justify-content-end">
                            <label for="" className="tab-form-label">
                              Operator ID
                            </label>
                          </div>
                          <div className="col-2 w-100">
                            <Select
                              styles={customStylesWithOutColorArrow}
                              placeholder="Select"
                              options={operatorDropDown}
                              getOptionLabel={(v) => v?.FirstName + " " + v?.LastName}
                              getOptionValue={(v) => v?.PIN}
                              isClearable
                              onInputChange={(inputValue, actionMeta) => {
                                if (inputValue.length > 12) {
                                  return inputValue.slice(0, 12);
                                }
                                return inputValue;
                              }}
                              isMulti
                              value={eventState?.operatorID}
                              onChange={(e) => { handleEventState("operatorID", e) }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-12 mb-1'>
                        <div className='row'>
                          <div className="col-2 d-flex align-self-center justify-content-end">
                            <label for="" className="tab-form-label">
                              RMS Incident # From
                            </label>
                          </div>
                          <div className="col-2 d-flex align-self-center justify-content-end">
                            <input
                              type='number'
                              className="form-control py-1 new-input"
                              name="RMSIncidentFrom"
                              placeholder="RMS Incident # From"
                              value={eventState.RMSIncidentFrom}
                              onChange={(e) => handleEventState("RMSIncidentFrom", e.target.value)}
                            />
                          </div>
                          <div className="col-2 d-flex align-self-center justify-content-end">
                            <label for="" className="tab-form-label">
                              RMS Incident # To
                            </label>
                          </div>
                          <div className="col-2 d-flex align-self-center justify-content-end">
                            <input
                              type='number'
                              className="form-control py-1 new-input"
                              name="RMSIncidentTo"
                              placeholder="RMS Incident # To"
                              value={eventState.RMSIncidentTo}
                              onChange={(e) => handleEventState("RMSIncidentTo", e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-12 mb-1'>
                        <div className='row'>
                          <div className="col-2 d-flex align-self-center justify-content-end">
                            <label for="" className="tab-form-label">
                              Reported CFS
                            </label>
                          </div>
                          <div className="col-5 d-flex align-self-center justify-content-end">
                            <Select
                              styles={colorLessStyle_Select}
                              placeholder="Select"
                              options={cfsDropDown}
                              getOptionLabel={(v) => v?.CFSCODE + "|" + v?.CFSCodeDescription}
                              getOptionValue={(v) => v?.CallforServiceID}
                              isClearable
                              formatOptionLabel={(option) => {
                                return option.CFSCODE + " | " + option.CFSCodeDescription
                              }}
                              value={eventState?.reportedCSF}
                              onChange={(e) => { handleEventState("reportedCSF", e) }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-12 mb-1'>
                        <div className='row'>
                          <div className="col-2 d-flex align-self-center justify-content-end">
                            <label for="" className="tab-form-label">
                              Found CFS
                            </label>
                          </div>
                          <div className="col-5 d-flex align-self-center justify-content-end">
                            <Select
                              styles={colorLessStyle_Select}
                              placeholder="Select"
                              options={cfsDropDown}
                              getOptionLabel={(v) => v?.CFSCODE}
                              getOptionValue={(v) => v?.CallforServiceID}
                              isClearable
                              formatOptionLabel={(option, { context }) => {
                                return context === 'menu' ? option.CFSCODE + " | " + option.CFSCodeDescription : option?.CFSCodeDescription
                              }}
                              value={eventState?.foundCSF}
                              onChange={(e) => { handleEventState("foundCSF", e) }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-12 mb-1'>
                        <div className='row'>
                          <div className="col-2 d-flex align-self-center justify-content-end">
                            <label for="" className="tab-form-label">
                              Resource Type
                            </label>
                          </div>
                          <div className="col-2 d-flex align-self-center justify-content-end">
                            <Select
                              styles={colorLessStyle_Select}
                              placeholder="Select"
                              options={resourceTypeData?.data?.data?.Table}
                              getOptionLabel={(v) => v?.ResourceTypeCode + " | " + v?.description}
                              getOptionValue={(v) => v?.ResourceTypeID}
                              formatOptionLabel={(option, { context }) => {
                                return context === 'menu'
                                  ? `${option?.ResourceTypeCode} | ${option?.description}`
                                  : option?.ResourceTypeCode;
                              }}
                              onInputChange={(inputValue, actionMeta) => {
                                if (inputValue.length > 12) {
                                  return inputValue.slice(0, 12);
                                }
                                return inputValue;
                              }}
                              isClearable
                              value={eventState?.resourceType}
                              onChange={(e) => { handleEventState("resourceType", e) }}
                            />
                          </div>
                          <div className="col-2 d-flex align-self-center justify-content-end">
                            <label for="" className="tab-form-label">
                              Resource#
                            </label>
                          </div>
                          <div className="col-2 w-100">
                            <Select
                              styles={customStylesWithOutColorArrow}
                              placeholder="Select"
                              options={resourceData}
                              getOptionLabel={(v) => v?.ResourceNumber}
                              getOptionValue={(v) => v?.ResourceID}
                              isClearable
                              isMulti
                              isSearchable
                              maxMenuHeight={145}
                              value={eventState?.resource}
                              onInputChange={(inputValue, actionMeta) => {
                                if (inputValue.length > 12) {
                                  return inputValue.slice(0, 12);
                                }
                                return inputValue;
                              }}
                              onChange={(e) => { handleEventState("resource", e) }}
                            />
                          </div>
                          <div className="col-2 d-flex align-self-center justify-content-end">
                            <label for="" className="tab-form-label">
                              Primary Unit
                            </label>
                          </div>
                          <div className="col-2 w-100">
                            <Select
                              styles={customStylesWithOutColorArrow}
                              placeholder="Select"
                              options={resourceData}
                              getOptionLabel={(v) => v?.ResourceNumber}
                              getOptionValue={(v) => v?.ResourceID}
                              isClearable
                              isMulti
                              value={eventState?.primaryUnit}
                              onInputChange={(inputValue, actionMeta) => {
                                if (inputValue.length > 12) {
                                  return inputValue.slice(0, 12);
                                }
                                return inputValue;
                              }}
                              onChange={(e) => { handleEventState("primaryUnit", e) }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-12 mb-1'>
                        <div className='row'>
                          <div className="col-2 d-flex align-self-center justify-content-end">
                            <label for="" className="tab-form-label">
                              Primary Officer
                            </label>
                          </div>
                          <div className="col-2 w-100">
                            <Select
                              styles={customStylesWithOutColorArrow}
                              placeholder="Select"
                              options={operatorDropDown}
                              getOptionLabel={(v) => v?.FirstName + " " + v?.LastName}
                              getOptionValue={(v) => v?.PIN}
                              isClearable
                              isMulti
                              value={eventState?.primaryOfficer}
                              onInputChange={(inputValue, actionMeta) => {
                                if (inputValue.length > 12) {
                                  return inputValue.slice(0, 12);
                                }
                                return inputValue;
                              }}
                              onChange={(e) => { handleEventState("primaryOfficer", e) }}
                            />
                          </div><div className="col-2 d-flex align-self-center justify-content-end">
                            <label for="" className="tab-form-label">
                              Shift
                            </label>
                          </div>
                          <div className="col-2 d-flex align-self-center justify-content-end">
                            <Select
                              styles={colorLessStyle_Select}
                              placeholder="Select"
                              options={shiftDropDown}
                              getOptionLabel={(v) => v?.ShiftCode + " | " + v?.ShiftDescription}
                              getOptionValue={(v) => v?.ShiftId}
                              formatOptionLabel={(option, { context }) => {
                                return context === 'menu'
                                  ? `${option?.ShiftCode} | ${option?.ShiftDescription}`
                                  : option?.ShiftCode;
                              }}
                              isClearable
                              value={eventState?.shift}
                              onChange={(e) => { handleEventState("shift", e) }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-12 mb-1'>
                        <div className='row'>
                          <div className="col-2 d-flex align-items-start justify-content-end">
                            <label className="tab-form-label">
                              Comments
                            </label>
                          </div>
                          <div className="col-10 d-flex align-items-center justify-content-end">
                            <textarea
                              type="text"
                              rows="3"
                              className="form-control  py-1 new-input"
                              style={{ height: "auto", overflowY: "scroll" }}
                              placeholder="Comment"
                              value={eventState?.Comments}
                              onChange={(e) => {
                                handleEventState("Comments", e.target.value)
                                e.target.style.height = "auto";
                                const maxHeight = 3 * parseFloat(getComputedStyle(e.target).lineHeight);
                                e.target.style.height = `${Math.min(e.target.scrollHeight, maxHeight)}px`;
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset>
                      <legend>Event Flag Information</legend>
                      <div className='col-12 mt-2 mb-1'>
                        <div className='row'>
                          <div className="col-2 d-flex align-self-center justify-content-end">
                            <label for="" className="tab-form-label">
                              Select Flag
                            </label>
                          </div>
                          <div className="col-2 d-flex align-self-center justify-content-end">
                            <Select
                              styles={colorLessStyle_Select}
                              placeholder="Select"
                              options={flagData}
                              isMulti
                              getOptionLabel={(v) => v?.FlagNameCode}
                              getOptionValue={(v) => v?.FlagID}
                              isClearable
                              value={eventState?.flag}
                              onChange={(e) => { handleEventState("flag", e) }}
                            />
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset>
                      <legend>Event Location Information</legend>
                      <div className='col-12 mb-1'>
                        <div className='row'>
                          <div className="col-2 d-flex align-self-center justify-content-end">
                            <label for="" className="tab-form-label">
                              Location
                            </label>
                          </div>
                          <div className="col-5 w-100 inner-input-fullw pr-2">
                            <Location
                              {...{
                                value: eventState,
                                setValue: setEventState,
                                locationStatus,
                                setLocationStatus,
                                setIsChangeData,
                                setIsSelectLocation,
                                locationData,
                              }}
                              col="location"
                              locationID="NameLocationID"
                              check={false}
                              verify={true}
                              page="Name"
                              isGEO
                            />
                          </div>
                          <div className="col-2 d-flex align-self-center justify-content-end">
                            <label for="" className="tab-form-label">
                              Apt#/suit
                            </label>
                          </div>
                          <div className="col-2 d-flex align-self-center justify-content-end">
                            <input
                              placeholder='APT#/suit'
                              type="number"
                              className="form-control ml-1 py-1 new-input mr-2"
                              maxLength={4}
                              value={eventState?.apt}
                              onChange={(e) => { handleEventState("apt", e.target.value) }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-12 mb-1'>
                        <div className='row'>
                          <div className="col-2 d-flex align-self-center justify-content-end">
                            <label for="" className="tab-form-label">
                              Intersection St/ST
                            </label>
                          </div>
                          <div className="col-5 d-flex align-items-center justify-content-end">
                            <input
                              type="text"
                              className="form-control py-1 new-input"
                              name="intersection1"
                              value={eventState.intersectionSt}
                              onChange={(e) => { handleEventState("intersectionSt", e.target.value) }}
                            />
                            {"/"}
                            <input
                              type="text"
                              className="form-control ml-1 py-1 new-input mr-2"
                              name="intersection2"
                              value={eventState.intersectionSt1}
                              onChange={(e) => { handleEventState("intersectionSt1", e.target.value) }}
                            />
                          </div>
                          <div className="col-2 d-flex align-self-center justify-content-end">
                            <label for="" className="tab-form-label">
                              City
                            </label>
                          </div>
                          <div className="col-2 d-flex align-self-center justify-content-end">
                            <input
                              type="text"
                              className="form-control ml-1 py-1 new-input mr-2"
                              name="intersection2"
                              value={eventState.city}
                              onChange={(e) => { handleEventState("city", e.target.value) }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-12 mb-1'>
                        <div className='row'>
                          <div className="col-2 d-flex align-self-center justify-content-end">
                            <label for="" className="tab-form-label">
                              Common Name Place
                            </label>
                          </div>
                          <div className="col-5 d-flex align-self-center justify-content-end">
                            <input
                              type="text"
                              className="form-control ml-1 py-1 new-input mr-2"
                              name="commonPlaceName"
                              value={eventState.commonPlaceName}
                              onChange={(e) => { handleEventState("commonPlaceName", e.target.value) }}
                            />
                          </div>
                          <div className="col-2 d-flex align-self-center justify-content-end">
                            <label for="" className="tab-form-label">
                              Zip
                            </label>
                          </div>
                          <div className="col-2 d-flex align-self-center justify-content-end">
                            <input
                              type="number "
                              className="form-control ml-1 py-1 new-input mr-2"
                              name="zip"
                              value={eventState.zip}
                              onChange={(e) => { handleEventState("zip", e.target.value) }}
                            />
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
              <div className="btn-box  text-right  mr-1 mb-1" >
                <button type="button" className="btn btn-sm btn-success mr-1" onClick={() => handleSearch()}>Search</button>
                <button type="button" data-dismiss="modal" className="btn btn-sm btn-success mr-1 ">Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EventSearchPage