import React, { useContext } from 'react'
import { AgencyContext } from '../../Context/Agency/Index';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { stringToBase64, tableCustomStyles } from '../../Components/Common/Utility';
import Tooltip from '../../CADComponents/Common/Tooltip';


const EventSearch = () => {
    const navigate = useNavigate();
    const { eventData } = useContext(AgencyContext);

    const businessColumns = [
        {
            name: <p className='text-end' style={{ position: 'absolute', top: '7px', }}>Action</p>,
            cell: row =>
                <div style={{ position: 'absolute', top: 4, }}>
                    {
                        <span onClick={(e) => set_Edit_Value(row)} className="btn btn-sm bg-green text-white px-1 py-0 mr-1">
                            <i className="fa fa-edit"></i>
                        </span>
                    }
                </div>,
            width: '50px',
        },
        {
            width: '120px',
            name: 'CAD Event #',
            selector: (row) => <>{row?.CADIncidentNumber || ""} </>,
            sortable: true
        },
        {
            width: '130px',
            name: 'RMS Incident #',
            selector: (row) => <>{row?.IncidentNumber || ""} </>,
            sortable: true
        },
        {
            width: '150px',
            name: 'Master Incident #',
            selector: (row) => <>{row?.MasterIncidentNumber || ""} </>,
            sortable: true
        },
        {
            width: '130px',
            name: 'CFS Code',
            selector: (row) => row.CFSCODE,
            sortable: true
        },
        {
            width: '140px',
            name: 'CFS Description',
            selector: (row) => <>{row?.CADCFSCode_Description || ""} </>,
            sortable: true
        },
        {
            width: '180px',
            name: 'Location',
            selector: (row) => <>{row?.CrimeLocation || ""} </>,
            sortable: true,
            cell: (row) => (
                <Tooltip text={row?.CrimeLocation || ''} maxLength={15} />
            ),
        },
        {
            width: '150px',
            name: 'Primary Officer',
            selector: (row) => <>{row?.primaryofficer || ""} </>,
            sortable: true
        },
        {
            width: '150px',
            name: 'Disposition Code',
            selector: (row) => <>{row?.DispositionCode || ""} </>,
            sortable: true
        },
        {
            width: '150px',
            name: 'Caller Name',
            selector: (row) => <>{row?.CallerName || ""} </>,
            sortable: true,
            cell: (row) => (
                <Tooltip text={row?.CallerName || ''} maxLength={15} />
            ),
        },
    ]

    const set_Edit_Value = (row) => {
        if (row?.IncidentID) {
            navigate(`/cad/event-search?IncId=${stringToBase64(row?.IncidentID)}&IncNo=${row?.CADIncidentNumber}&IncSta=true`)
        }
    }

    return (
        <>
            <div className="section-body view_page_design pt-1">
                <div className="row clearfix" >
                    <div className="col-12 col-sm-12">
                        <div className="card Agency name-card">
                            <div className="card-body">
                                <div className="row  ">
                                    <div className={`col-12 col-md-12`}>
                                        <div className="row">
                                            <div className="col-12  ">
                                                <div className="row">
                                                    <div className="col-12 " >
                                                        <DataTable
                                                            dense
                                                            columns={businessColumns}
                                                            data={eventData}
                                                            selectableRowsHighlight
                                                            highlightOnHover
                                                            fixedHeader
                                                            pagination
                                                            paginationPerPage={'100'}
                                                            paginationRowsPerPageOptions={[100, 150, 200, 500]}
                                                            showPaginationBottom={100}
                                                            persistTableHead={true}
                                                            customStyles={tableCustomStyles}
                                                            responsive
                                                            fixedHeaderScrollHeight='450px'
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EventSearch


